import styled from 'styled-components';
import { UserAvatar } from '../../../components/UserProfiles/UserAvatar';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { SharedImage, UserDetails } from '../../../types';
import {
  DisplayName,
  AmountOfSharedImages,
  AmountOfKarma,
} from '../ProfileTopBarComponents';
import { desktopMediaQuery } from '../../../styleHelpers';

interface Props {
  userDetails: UserDetails | undefined;
  sharedImages: SharedImage[];
}

const ProfileDetailsTopBar = ({ userDetails, sharedImages }: Props) => {
  if (!userDetails) {
    return <LoadingIndicator differentBgColor />;
  }

  return (
    <Container>
      <LeftSideContainer>
        <UserAvatar url={userDetails?.profileImageUrl} />
        <DisplayName
          displayName={userDetails?.displayName}
          isGold={userDetails?.type === 'gold'}
          isPublicProfile
        />
      </LeftSideContainer>

      <RightSideContainer>
        <AmountOfSharedImages sharedImages={sharedImages} />
        <AmountOfKarma sharedImages={sharedImages} />
      </RightSideContainer>
    </Container>
  );
};

export default ProfileDetailsTopBar;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  padding-bottom: 32px;

  ${desktopMediaQuery} {
    width: 50vw;
    margin-left: 20vw;
  }
`;

const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${desktopMediaQuery} {
    flex-direction: row;
    gap: 32px;
    margin-right: 5vw;
  }
  align-items: center;
  flex: 2;
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${desktopMediaQuery} {
    gap: 2.5vw;
    flex: 4;
  }

  flex: 3;

  gap: 16px;
`;
