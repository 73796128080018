import styled from 'styled-components';
import { BACKGROUND_COLOR, BORDER_COLOR, PRIMARY_COLOR } from '../../constants';
import { TabInCreateCharacter } from '../../types';
import {
  FaGear,
  FaPerson,
  FaPersonCircleQuestion,
  FaUser,
} from 'react-icons/fa6';
import { desktopMediaQuery } from '../../styleHelpers';

interface TabSelectorInMagicModeProps {
  activeTab: TabInCreateCharacter;
  setActiveTab: (tab: TabInCreateCharacter) => void;
}
const TabSelectorInCreateCharacter = ({
  activeTab,
  setActiveTab,
}: TabSelectorInMagicModeProps) => {
  return (
    <Container>
      <TabButtonContainer>
        <TabButton
          isSelected={activeTab === 'base'}
          onClick={() => setActiveTab('base')}
        >
          <FaPersonCircleQuestion />
          Base
        </TabButton>
        <TabButton
          isSelected={activeTab === 'head'}
          onClick={() => setActiveTab('head')}
        >
          <FaUser />
          Head
        </TabButton>
        <TabButton
          isSelected={activeTab === 'body'}
          onClick={() => setActiveTab('body')}
        >
          <FaPerson />
          Body
        </TabButton>
        <TabButton
          isSelected={activeTab === 'other'}
          onClick={() => setActiveTab('other')}
        >
          <FaGear />
          Other
        </TabButton>
      </TabButtonContainer>
    </Container>
  );
};

// when advanced / misc is added, then at least tattoos? what else?
// maybe stuff you can add to face, like eyes shape, eye color etc?

const Container = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 2;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  width: 100vw;

  ${desktopMediaQuery} {
    width: 80vw;
    left: 20vw;
  }

  position: sticky;
  top: 28px;
  background-color: transparent;

  padding-top: 16px;
  padding-bottom: 8px;

  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

  background-color: ${BACKGROUND_COLOR};
`;

const TabButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 95%;
`;

interface TabButtonProps {
  isSelected: boolean;
}

const TabButton = styled.button<TabButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  border-bottom: 1px solid
    ${({ isSelected }) => (isSelected ? PRIMARY_COLOR : BORDER_COLOR)};

  padding-left: 0;
  padding-right: 0;

  color: ${({ isSelected }) => (isSelected ? PRIMARY_COLOR : 'inherit')};

  background-color: ${BACKGROUND_COLOR};

  gap: 4px;

  transition: border 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    border-bottom: 1px solid ${PRIMARY_COLOR};
  }

  height: 56px;

  flex: 1;
  width: auto;
`;

export default TabSelectorInCreateCharacter;
