import styled from 'styled-components';
import { SharedImage } from '../../../types';
import { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import { desktopMediaQuery } from '../../../styleHelpers';
import { FaEllipsisV } from 'react-icons/fa';
import ShowMoreMenuModal from './ShowMoreMenuModal';
import UserNameAndAvatar from '../../../components/ImageStuff/UserNameAndAvatar';

interface TopBarInImageDetailProps {
  image: SharedImage | null;
}

const TopBarInImageDetail = ({ image }: TopBarInImageDetailProps) => {
  const handleGoBack = () => {
    window.history.back();
  };

  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const handleShowMoreMenu = () => {
    setShowMoreMenu(true);
  };

  return (
    <>
      <TopBarContainer>
        <GoBackButton onClick={handleGoBack} />

        <UserNameAndAvatar image={image} />

        <ShowMenuIconContainer onClick={handleShowMoreMenu}>
          <FaEllipsisV />
        </ShowMenuIconContainer>
      </TopBarContainer>

      <ShowMoreMenuModal
        show={showMoreMenu}
        setShow={setShowMoreMenu}
        image={image}
      />
    </>
  );
};

export default TopBarInImageDetail;

const TopBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;

  height: 37px;

  ${desktopMediaQuery} {
    height: 80px;
    max-width: 784px;
  }
`;

const GoBackButton = styled(FaArrowLeft)`
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 4px;
  font-size: 18px;

  ${desktopMediaQuery} {
    font-size: 24px;
    margin-left: 32px;
    &:hover {
      cursor: pointer;
    }
  }
`;

const ShowMenuIconContainer = styled.div`
  margin-left: auto;
  margin-right: 16px;
  margin-top: 10px;
  ${desktopMediaQuery} {
    margin-right: 152px;

    &:hover {
      cursor: pointer;
    }
  }
`;
