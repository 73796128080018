import React, { useState } from 'react';
import styled from 'styled-components';
import { GenericSetState, SharedImage, UserComment } from '../../../types';
import { SingleLineInputFieldInForm } from '../AuthModal.tsx/LoginOrRegisterForm';
import { FaPaperPlane } from 'react-icons/fa6';
import { LIGHT_GREY_COLOR } from '../../../constants';
import { v4 as uuidv4 } from 'uuid';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { toast } from 'react-toastify';
import useSendComment from '../../../hooks/feed/useSendComment';
import LoadingIndicator from '../../common/LoadingIndicator';
import useSetDisplayName from '../../../hooks/userHandling/useSetDisplayName';

interface AddANewCommentProps {
  image: SharedImage;
  setCommentsToRender: GenericSetState<UserComment[]>;
}

const AddANewComment: React.FC<AddANewCommentProps> = ({
  image,
  setCommentsToRender,
}) => {
  const sendComment = useSendComment();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const { loggedInUser } = useLoggedInUserContext();
  const { setDisplayName } = useSetDisplayName();

  const handleSendComment = async () => {
    setLoading(true);
    if (comment.trim() === '') {
      setLoading(false);
      return;
    }

    if (!loggedInUser) {
      toast.error('You need to be logged in to post a comment');
      setLoading(false);
      return;
    }

    if (!loggedInUser.displayName) {
      alert(
        'Please set a display name for others to see. You can change it anytime in your profile',
      );
      await setDisplayName();
    }

    // This is not the object that's finally saved on the backend
    const newComment: UserComment = {
      id: uuidv4(),
      text: comment,
      timestamp: new Date(),
      user: loggedInUser,
      likes: 0,
      amountOfReports: 0,
    };
    const status = await sendComment(image.id, comment);

    if (status === 200) {
      setComment('');
      setCommentsToRender((prev) => [...prev, newComment]);
    } else {
      toast.error('Failed to post comment');
    }
    setLoading(false);
  };

  return (
    <Container>
      <StyledInputField
        placeholder="Add a comment..."
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSendComment();
          }
        }}
      />
      {loading ? (
        <LoadingIndicator differentBgColor />
      ) : (
        <PaperPlaneIcon onClick={handleSendComment} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  border: 1.5px solid ${LIGHT_GREY_COLOR};
  border-radius: 8px;
`;

const StyledInputField = styled(SingleLineInputFieldInForm)`
  flex-grow: 1;
  margin-right: 8px;
`;

const PaperPlaneIcon = styled(FaPaperPlane)`
  font-size: 22px;
  cursor: pointer;
  margin-right: 10px;
`;

export default AddANewComment;
