import React, { useEffect } from 'react';
import styled from 'styled-components';
import HomePageImageFeed from './HomePageImageFeed';
import { BORDER_COLOR } from '../../constants';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import LoadingAnimation from '../../components/common/LoadingStuff/LoadingAnimation';
import NotificationBar from './HomePageNotification/NotificationBar';
import { scrollToTop } from '../../hooks/misc/helpers';
import HomePageGenerator from '../HomePageGenerator/HomePageGenerator';
import { desktopMediaQuery } from '../../styleHelpers';

const HomePage: React.FC = () => {
  const { isLoadingLogin } = useLoggedInUserContext();

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <OuterContainer>
      <NotificationBar />

      <HomePageGenerator />

      <SectionSeparator />

      {isLoadingLogin ? (
        <LoadingAnimation
          loaderToChoose={2}
          loading={true}
          style={{ marginTop: '80px' }}
        />
      ) : (
        <HomePageImageFeed />
      )}
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 120px;

  margin-top: -12px;
`;

const SectionSeparator = styled.div`
  height: 8px;
  width: 100%;
  max-width: 1200px;
  border-bottom: 0.5px dotted ${BORDER_COLOR};

  margin-bottom: 16px;

  ${desktopMediaQuery} {
    margin-left: 10vw;
  }
`;

export default HomePage;
