import { FaCommentDots } from 'react-icons/fa6';
import styled from 'styled-components';
import { PRIMARY_COLOR_DIMMED } from '../../constants';
import { SmallStyledButton } from './StyledButton';

interface P {
  onClick: () => void;
}

const StartChatButton = ({ onClick }: P) => {
  return (
    <Button onClick={onClick}>
      <FaCommentDots />
      Start Chat
    </Button>
  );
};

const Button = styled(SmallStyledButton)`
  box-shadow: 4px 4px 4px ${PRIMARY_COLOR_DIMMED};
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 4px;
  padding-top: 4px;
  font-size: 16px;
`;

export default StartChatButton;
