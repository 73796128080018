import React from 'react';
import { MdOutlineLayers } from 'react-icons/md';
import {
  ContainerForSelect,
  StyledSelectForBelowImage,
} from './SharedComponentsInBelowImage';
import { useImageGenerationSettingsContext } from '../../../context/ImageGenerationSettingsProvider';
import { getWaitTimeForSpeedMode } from '../../../generationConstants';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { toast } from 'react-toastify';

interface AmountToGenerateSelectorProps {
  amountToGenerate: number;
  setAmountToGenerate: (amount: number) => void;
  wider?: boolean;
}

const AmountToGenerateSelector: React.FC<AmountToGenerateSelectorProps> = ({
  amountToGenerate,
  setAmountToGenerate,
  wider,
}) => {
  const { loggedInUser } = useLoggedInUserContext();
  const { speedMode, engine } = useImageGenerationSettingsContext();

  const handleChange = (e: any) => {
    const value = parseInt(e.target.value);
    if (!loggedInUser && value > 1) {
      toast.error(
        'You need to be logged in to generate more than 1 image at a time.',
      );
      return;
    }

    if (speedMode === "adTurbo" && value >1) {
        alert("Not possible 😭")
      return;
  }

    setAmountToGenerate(value);

    const timeToGenerate = getWaitTimeForSpeedMode(speedMode) * value;
    if (timeToGenerate > 320) {
      alert(
        'Warning: If the site closes unexpectedly during generation, you may lose the images.',
      );
    }
  };

  const getValueToRender = (value: number) => {
    let timeToGenerate = getWaitTimeForSpeedMode(speedMode) * value;
    if (value > 1) {
      if (speedMode === 'adTurbo') {
        return "Only 1X possible in ad mode";
      } else {
        timeToGenerate *= 0.9;
      }
    }

    if (speedMode === 'adTurbo') return `1x - ${timeToGenerate} sec`;
    const minutes = Math.floor(timeToGenerate / 60);
    const seconds = Math.ceil(timeToGenerate % 60);
    return `${value}x - ${minutes}:${seconds < 10 ? '0' : ''}${seconds} min`;
  };

  const isSuperEngine = engine?.modelId === 'superv1' || engine?.modelId === 'superv1mini';

  return (
    <ContainerForSelect wider={wider}>
      <MdOutlineLayers size={20} />
      <StyledSelectForBelowImage
        value={amountToGenerate}
        onChange={handleChange}
      >
        <option value={1}>{getValueToRender(1)}</option>
        {
          !isSuperEngine && (
            <>
              <option value={2}>{getValueToRender(2)}</option>
              <option value={3}>{getValueToRender(3)}</option>
              <option value={4}>{getValueToRender(4)}</option>
              <option value={5}>{getValueToRender(5)}</option>
              <option value={6}>{getValueToRender(6)}</option>
              <option value={7}>{getValueToRender(7)}</option>
              <option value={8}>{getValueToRender(8)}</option>
            </>
          )
        }
      </StyledSelectForBelowImage>
    </ContainerForSelect>
  );
};

export default AmountToGenerateSelector;
