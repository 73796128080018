import styled from 'styled-components';
import NegativePromptBox from './AdvancedOptions/NegativePromptBox';
import UploadReferenceImage from './AdvancedOptions/UploadReferenceImage';
import { StyleInterface } from '../../types';
import {
  DESKTOP_WIDTH,
  LIGHT_GREY_COLOR,
  SECONDARY_TEXT_COLOR,
} from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { isSuperEngine } from '../../utils/engineHelpers';

interface P {
  negativePrompt: string;
  handleSetNegativePrompt: (negativePrompt: string) => void;
  selectedStyle: StyleInterface;
}

const OptionalPart = ({
  negativePrompt,
  handleSetNegativePrompt,
  selectedStyle,
}: P) => {
  const { isCreatingVariations, engine } = useImageGenerationSettingsContext();

  const showUploadPart = !isCreatingVariations && !isSuperEngine(engine);
  const shouldHideOptionalPart = isSuperEngine(engine);

  if (shouldHideOptionalPart) return null;

  return (
    <OptionalPartContainer>
      <OptionalText>Optional</OptionalText>
      <NegativePromptBox
        negativePrompt={negativePrompt}
        handleSetNegativePrompt={handleSetNegativePrompt}
        selectedStyle={selectedStyle}
      />
      {showUploadPart && <UploadReferenceImage />}
    </OptionalPartContainer>
  );
};

const OptionalPartContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 36px;

  padding-left: 8px;

  @media (min-width: ${DESKTOP_WIDTH}px) {
    width: 100%;
    position: absolute;
    right: 0;
    top: 10px;
    max-width: 450px;
    margin: 64px;
    margin-right: 2.1vw;
  }
`;

const OptionalText = styled.div`
  border-top: 1px solid ${LIGHT_GREY_COLOR};
  ${desktopMediaQuery} {
    border: none;
  }
  color: ${SECONDARY_TEXT_COLOR};
  font-size: 14px;

  padding-top: 24px;
  padding-bottom: 36px;

  display: flex;
  justify-content: center;
`;

export default OptionalPart;
