import { ChatMessage } from "../types";

class ChatStorage {
  private storageKey: string;
  private maxMessages: number;

  constructor() {
    this.storageKey = 'userChats';
    this.maxMessages = 80;
  }

  getMessages(): ChatMessage[] {
    const messages = localStorage.getItem(this.storageKey);
    const parsedMessages = messages ? JSON.parse(messages) : [];
    return parsedMessages.slice(-this.maxMessages);
  }

  addMessage(message: ChatMessage): void {
    const messages = this.getMessages();
    messages.push(message);
    localStorage.setItem(this.storageKey, JSON.stringify(messages));
  }

  clearMessages(): void {
    localStorage.removeItem(this.storageKey);
  }
}

export default ChatStorage;