import { useGeneralContext } from '../../../context/GeneralContextProvider';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  GOLD_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR_DIMMED,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import styled from 'styled-components';
import { EngineType, SpeedModeType } from '../../../types';
import { BiCoin } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { getCreditsRequired } from '../../../components/helpers';
import { StyledButton } from '../../../components/common/StyledButton';

interface P {
  handleSendPrompt: () => void;
  inInpaintingPage?: boolean;
  speedMode: SpeedModeType;
  amountToGenerate?: number;
  engine?: EngineType;
  inCreateCharacter?: boolean;
  shouldChangeToGold?: boolean;
}

const GenerateButton = ({
  handleSendPrompt,
  inInpaintingPage,
  speedMode,
  amountToGenerate,
  engine,
  inCreateCharacter,
  shouldChangeToGold,
}: P) => {
  const { loading, activeImage } = useGeneralContext();
  const [amountOfCreditsToGenerate, setAmountOfCreditsToGenerate] = useState(0);

  const handleClick = () => {
    if (activeImage) {
      handleSendPrompt();
    }
  };

  useEffect(() => {
    const amountToGen = getCreditsRequired(speedMode, amountToGenerate, engine);
    setAmountOfCreditsToGenerate(amountToGen);
  }, [speedMode, amountToGenerate, engine]);

  const textToDisplay = inCreateCharacter ? '✨ Create' : 'Generate';

  return (
    <StyledGenerateButton
      onClick={handleClick}
      gold={!!shouldChangeToGold}
      inInpaitingPage={inInpaintingPage}
      inCreateCharacter={inCreateCharacter}
    >
      {loading ? (
        <div style={{ marginTop: '-4px', marginBottom: '-5px' }}>
          <LoadingIndicator differentBgColor />
        </div>
      ) : (
        <ContentContainer>
          <Text>{textToDisplay}</Text>
          <AmountContainer>
            <BiCoin color={GOLD_COLOR} />
            {amountOfCreditsToGenerate}
          </AmountContainer>
        </ContentContainer>
      )}
    </StyledGenerateButton>
  );
};

const StyledGenerateButton = styled(StyledButton)<{
  gold: boolean;
  inInpaitingPage?: boolean;
  inCreateCharacter?: boolean;
}>`
  border-radius: 8px;
  font-size: 16px;
  border: 1px solid ${BORDER_COLOR};
  color: ${PRIMARY_COLOR};
  margin-top: 10px;
  color: ${PRIMARY_TEXT_COLOR};
  border-color: ${(props) => (props.gold ? GOLD_COLOR : PRIMARY_COLOR)};
  box-shadow: ${(props) =>
    props.gold
      ? `2px 1px 2px ${GOLD_COLOR}`
      : `4px 4px 4px ${PRIMARY_COLOR_DIMMED}`};
  background-color: ${BACKGROUND_COLOR};

  padding: 16px;
  padding-top: 17px;
  width: ${(props) => (props.inInpaitingPage ? 'auto' : '100%')};
  height: 65px;
  align-items: center;
  display: flex;
  justify-content: center;

  height: ${(props) => (props.inCreateCharacter ? '52px' : '65px')};

  transition: border 0.3s ease-in-out;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div`
  display: flex;
  flex-direction: row;
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 4px;
`;

export default GenerateButton;
