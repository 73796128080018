import styled from 'styled-components';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import {
  AmountOfKarma,
  AmountOfSharedImages,
  DisplayName,
  UsernamePart,
} from './ProfileTopBarComponents';
import { desktopMediaQuery } from '../../styleHelpers';
import { UserAvatar } from '../../components/UserProfiles/UserAvatar';
import { useImagesContext } from '../../context/ImagesContextProvider';

const ProfileTopBar = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const { sharedImages } = useImagesContext();
  if (!loggedInUser) return null;

  return (
    <Container>
      <LeftSideContainer>
        <UserAvatar url={loggedInUser?.profileImageUrl} showEditButton />
        <DisplayName
          displayName={loggedInUser?.displayName}
          isGold={loggedInUser?.type === 'gold'}
        />
      </LeftSideContainer>

      <RightSideContainer>
        <UsernamePart username={loggedInUser.username} />
        <StatsContainer>
          <AmountOfSharedImages sharedImages={sharedImages} />
          <AmountOfKarma sharedImages={sharedImages} />
        </StatsContainer>
      </RightSideContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  padding-bottom: 32px;
`;

const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${desktopMediaQuery} {
    flex-direction: row;
    gap: 32px;
    margin-right: 5vw;
  }
  align-items: center;
  flex: 2;
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 0px;

  ${desktopMediaQuery} {
    flex-direction: row;
    gap: 2.5vw;
    flex: 4;
  }

  flex: 3;
  align-items: center;

  gap: 16px;
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export default ProfileTopBar;
