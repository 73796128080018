import styled from 'styled-components';
import { DESKTOP_WIDTH, PRIMARY_COLOR } from '../../constants';
import { useNavigate } from 'react-router-dom';

interface I {
  setShowFirstTimeModal: (show: boolean) => void;
  navigateToContentPolicy: () => void;
  navigateToDisputeResolution: () => void;
}

const Footer = ({
  setShowFirstTimeModal,
  navigateToContentPolicy,
  navigateToDisputeResolution,
}: I) => {
  const baseUrl = window.location.origin;
  const navigate = useNavigate();
  const navigateToRefundPolicy = () => {
    navigate('/refund-policy');
  };

  const handleContactUs = () => {
    alert('Contact us at: hello@uncensored.ai');
  };
  return (
    <Wrapper>
      <LinkInFooter onClick={() => setShowFirstTimeModal(true)}>
        Terms of Service & Privacy Policy
      </LinkInFooter>
      <LinkInFooter onClick={() => navigateToContentPolicy()}>
        Content Policy
      </LinkInFooter>

      <LinkInFooter onClick={handleContactUs}>Contact Us</LinkInFooter>

      <LinkInFooter onClick={() => navigateToDisputeResolution()}>
        Dispute Resolution
      </LinkInFooter>

      <LinkInFooter onClick={() => navigateToRefundPolicy()}>
        Refund Policy
      </LinkInFooter>

      <NiceLink href={`${baseUrl}/blog`} target="_blank">
        <span>Blog</span>
      </NiceLink>

      <Copyright>
        © 2024 AP Software Ltd
        <br />
        Henry Fordin Katu 5
        <br />
        All rights reserved
        <br />
      </Copyright>

      <CardBrandLogos>
        Accepting:
        <img
          src="https://www.visa.com.sg/dam/VCOM/regional/ve/romania/blogs/hero-image/visa-logo-800x450.jpg"
          alt="visa"
        />
        <img
          src="https://pentagram-production.imgix.net/ee458d4d-a8e8-4177-b2a8-97df766e2e99/lh_mb_mastercard_02.jpg?rect=%2C%2C%2C&w=640&crop=1&fm=jpg&q=70&auto=format&fit=crop&h=400"
          alt="mastercard"
        />
      </CardBrandLogos>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding-bottom: 16px;
  font-size: 12px;
  @media (max-width: ${DESKTOP_WIDTH}px) {
    padding-bottom: 100px;
  }
`;

const NiceLink = styled.a`
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.div``;

const CardBrandLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  img {
    width: 50px;
    height: 30px;
  }
`;

const LinkInFooter = styled.a`
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
