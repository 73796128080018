import { useCookies } from 'react-cookie';
import { customFetch } from '../customFetch';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { getRandomSeed } from '../../components/helpers';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { FakeImage } from '../../types';
import { scrollToTop } from '../misc/helpers';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { addImageToLocalStorage } from '../../localStorage/imageStorage';
import { handlePopUnderAd } from './helpers';
import useHandleDeductCredits from './useHandleDeductCredits';
import { getCreditsRequired } from '../../components/helpers';
import { waitSeconds } from '../../components/helpers';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { useModalsContext } from '../../context/ModalsContextProvider';

// TODO: double checkaa kunnol skulaaks tää

const useHandleSendFluxImageRequest = () => {
  const [{ token }] = useCookies(['token']);
  const { loggedInUser } = useLoggedInUserContext();
  const {
    isSeedFrozen,
    setActiveImage,
    setImages,
    setEstimateGenerationReadyTimestamp,
    setGenerationStartedTimestamp,
    setLoading,
    loading,
    isFirstTimeUser,
  } = useGeneralContext();
  const {
    userPrompt,
    negativePrompt,
    seedState: seed,
    setSeed,
    size,
    cfg,
    amountToGenerate,
    uploadedImageUrl,
    speedMode,
    engine,
  } = useImageGenerationSettingsContext();
  const handleDeductCredits = useHandleDeductCredits();

  const { setShowHintToRegisterModal, hasClosedHintToRegisterModal } =
    useModalsContext();

  const getSeed = () => {
    if (isSeedFrozen) {
      if (seed > 4294967294) return getRandomSeed();
      if (seed === 0) return getRandomSeed();
      return seed as number;
    }

    const randomSeed = getRandomSeed();
    setSeed(randomSeed);
    return randomSeed;
  };

  const handleSendFluxImageRequest = async (): Promise<number> => {
    if (loggedInUser?.username !== 'malossi' && loading) {
      toast.error('Please wait for the current generation to complete');
      return 500;
    }

    if (
      !loggedInUser &&
      !hasClosedHintToRegisterModal &&
      speedMode === 'turbo' &&
      !isFirstTimeUser
    ) {
      setShowHintToRegisterModal(true);
      return 500;
    }

    if (speedMode === 'adTurbo' && engine.modelId === 'superv1') {
      toast.error('Ad mode not available for Super V1. Please use Super V1 Mini instead.');
      return 500;
    }

    if (speedMode !== 'turbo' && speedMode !== 'adTurbo') {
      toast.error('Super is only available in Turbo and Ad Turbo modes');
      return 500;
    }

    setLoading(true);
    const now = Date.now();
    setGenerationStartedTimestamp(now);
    const expectedWait = 23 * 1000;
    setEstimateGenerationReadyTimestamp(now + expectedWait);

    handlePopUnderAd(speedMode);

    const creditsRequired = getCreditsRequired(
      speedMode,
      amountToGenerate,
      engine,
    );
    const handleDeductCreditsResult = handleDeductCredits(creditsRequired);

    if (handleDeductCreditsResult !== 'ok') {
      toast.error("You don't have enough credits to use Super");
      await waitSeconds(1);
      setLoading(false);
      return 500;
    }

    if (amountToGenerate > 1) {
      toast.error(
        'Currently only one image can be generated with Super at a time',
      );
      setLoading(false);
      return 500;
    }

    const functionName = 'generateSuperImage';

    const trackId = uuidv4();

    const imageRequestObject = {
      fullPrompt: userPrompt,
      negativePrompt: negativePrompt,
      seed: getSeed(),
      size,
      cfg,
      amountToGenerate,
      uploadedImageUrl,
      trackId,
      engine,
      speedMode,
    };

    const response = await customFetch(functionName, {
      imageRequestObject,
      token,
    });
    console.log(response);

    const { data } = response;

    if (!data.url) {
      toast.error('Failed to generate image');
      setLoading(false);
      return 500;
    }

    const imageToAdd: FakeImage = {
      imageUrl: data.url,
      trackId,
      guidanceScale: cfg,
      engine,
      negativePrompt,
      prompt: userPrompt,
      seed: imageRequestObject.seed,
      height: Number(size.height),
      width: Number(size.width),
    };

    setImages((prevImages) => [...prevImages, imageToAdd]);
    setActiveImage(imageToAdd);
    addImageToLocalStorage(imageToAdd);

    scrollToTop();

    return 200;
  };

  return handleSendFluxImageRequest;
};

export default useHandleSendFluxImageRequest;
