import styled from 'styled-components';
import { BACKGROUND_COLOR, SECONDARY_TEXT_COLOR } from '../../../constants';
import { desktopMediaQuery } from '../../../styleHelpers';

export const TopBarContainer = styled.div<{ isVisible: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${BACKGROUND_COLOR};
  color: ${SECONDARY_TEXT_COLOR};
  box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.2);

  // Note: if changing these a lot remember to change EmptySpace in MainApp.tsx
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 8px;

  width: 93vw;
  ${desktopMediaQuery} {
    padding-left: 64px;
    padding-right: 48px;
  }

  transition: top 0.3s;
  position: fixed;
  top: ${(props) => (props.isVisible ? '0' : '-100px')};
  left: 0;
  right: 0;
  z-index: 10;
`;

export const LeftSideContainerInTopBar = styled.div`
  cursor: pointer;
`;

export const RightSideContainerInTopBar = styled.div`
  display: flex;
  gap: 8px;
`;
