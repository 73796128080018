import { toast } from 'react-toastify';
import styled from 'styled-components';
import SaveToFolderModal from '../../../components/Modals/SaveToFolderModal/SaveToFolderModal';
import SaveToast from '../../../components/common/SaveToast';
import { useSaveImageLogic } from '../../../hooks/images/useSaveImageLogic';
import { FakeImage } from '../../../types';
import { PRIMARY_COLOR } from '../../../constants';
import { desktopMediaQuery } from '../../../styleHelpers';
import { FaHeart, FaRegHeart } from 'react-icons/fa6';
import { fadeIn } from '../../../components/ImageStuff/animations';
import { TIME_TO_CLOSE_TOAST } from '../../../MainApp';

interface P {
  image: FakeImage;
  showSaveText?: boolean;
  big?: boolean;
  additionalCallbackfn?: () => void;
}

export default function SaveButton(props: P) {
  const triggerToast = () => {
    const handleSaveToFolder = () => {
      setShowSaveToFolderModal(true);
    };

    toast(
      <SaveToast
        imageSrc={props.image.imageUrl}
        onSaveToFolder={handleSaveToFolder}
      />,
      {
        className: 'SaveToast',
        autoClose: TIME_TO_CLOSE_TOAST,
        position: 'bottom-center',
      },
    );
  };

  const {
    isSaved,
    showSaveToFolderModal,
    setShowSaveToFolderModal,
    handleSaveImage,
  } = useSaveImageLogic({ ...props, triggerToast });

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const additionalCallbackfn = props.additionalCallbackfn;
    if (additionalCallbackfn) {
      additionalCallbackfn();
    }
    handleSaveImage();
  };

  const size = props.big ? 26 : 23;

  return (
    <>
      <Container onClick={handleOnClick}>
        {isSaved ? (
          <SaveIconActive color={PRIMARY_COLOR} size={size} />
        ) : (
          <SaveIconInactive  size={size} />
        )}
        {props.showSaveText && 'Save'}
      </Container>
      <SaveToFolderModal
        show={showSaveToFolderModal}
        setShow={setShowSaveToFolderModal}
        image={props.image}
      />
    </>
  );
}


const Container = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  gap: 4px;
  font-size: 16px;
  transition: color 0.2s;

  ${desktopMediaQuery} {
    &:hover {
      color: ${PRIMARY_COLOR};
    }
  }
`;

const SaveIconInactive = styled(FaRegHeart)`
  animation: ${fadeIn} 0.1s ease-in-out;
`;

const SaveIconActive = styled(FaHeart)`
  animation: ${fadeIn} 0.1s ease-in-out;
`;
