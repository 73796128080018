import { Outlet, useNavigate } from 'react-router-dom';
import { useChatContext } from '../../context/ChatContextProvider';
import { useEffect } from 'react';
import { CHAT_LIST_PATH, CHAT_CONVERSATION_PATH } from '../../pathNames';

const ChatPage = () => {
  const navigate = useNavigate();
  const { activeChatId } = useChatContext();

  useEffect(() => {
    if (!activeChatId) {
      navigate(CHAT_LIST_PATH);
    } else {
      navigate(CHAT_CONVERSATION_PATH);
    }
  }, [activeChatId]);

  return <Outlet />;
};

export default ChatPage;
