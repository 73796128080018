import { createContext, useContext, useState, ReactNode } from 'react';
import { GenericSetState } from '../types';

interface IModalsContext {
  isAuthModalOpen: boolean;
  setIsAuthModalOpen: GenericSetState<boolean>;
  authModalVariant: 'login' | 'register';
  setAuthModalVariant: GenericSetState<'login' | 'register'>;

  showHintToRegisterModal: boolean;
  setShowHintToRegisterModal: GenericSetState<boolean>;
  hasClosedHintToRegisterModal: boolean;
  setHasClosedHintToRegisterModal: GenericSetState<boolean>;

  showChannelListModal: boolean;
  setShowChannelListModal: GenericSetState<boolean>;

  isPromptOpen: boolean;
  modalMessage: string;
  onConfirmCallback?: (input: string | null) => void;
  openPromptModal: (message: string, onConfirm: (input: string | null) => void) => void;
  closePromptModal: () => void;
}

const defaultState: IModalsContext = {
  isAuthModalOpen: false,
  setIsAuthModalOpen: undefined as unknown as GenericSetState<boolean>,
  authModalVariant: 'login',
  setAuthModalVariant: undefined as unknown as GenericSetState<
    'login' | 'register'
  >,

  showHintToRegisterModal: false,
  setShowHintToRegisterModal: undefined as unknown as GenericSetState<boolean>,
  hasClosedHintToRegisterModal: false,
  setHasClosedHintToRegisterModal:
    undefined as unknown as GenericSetState<boolean>,

  showChannelListModal: false,
  setShowChannelListModal: undefined as unknown as GenericSetState<boolean>,

  isPromptOpen: false,
  modalMessage: '',
  openPromptModal: () => {},
  closePromptModal: () => {},
};

const ModalsContext = createContext<IModalsContext>(defaultState);

export const useModalsContext = () => {
  const context = useContext(ModalsContext);
  if (!context) throw new Error('useModalsContext must be used within ModalsContextProvider');
  return context;
};

interface ModalsContextProviderProps {
  children: ReactNode;
}

function ModalsContextProvider({ children }: ModalsContextProviderProps) {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [authModalVariant, setAuthModalVariant] = useState<'login' | 'register'>('login');
  const [showHintToRegisterModal, setShowHintToRegisterModal] = useState(false);
  const [hasClosedHintToRegisterModal, setHasClosedHintToRegisterModal] = useState(false);
  const [showChannelListModal, setShowChannelListModal] = useState(false);

  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [onConfirmCallback, setOnConfirmCallback] = useState<(input: string | null) => void>();

  const openPromptModal = (message: string, onConfirm: (input: string | null) => void) => {
    setModalMessage(message);
    setOnConfirmCallback(() => onConfirm);
    setIsPromptOpen(true);
  };

  const closePromptModal = () => {
    setIsPromptOpen(false);
    setModalMessage('');
    setOnConfirmCallback(undefined);
  };

  return (
    <ModalsContext.Provider
      value={{
        isAuthModalOpen,
        setIsAuthModalOpen,
        authModalVariant,
        setAuthModalVariant,
        showHintToRegisterModal,
        setShowHintToRegisterModal,
        hasClosedHintToRegisterModal,
        setHasClosedHintToRegisterModal,
        showChannelListModal,
        setShowChannelListModal,
        isPromptOpen,
        modalMessage,
        onConfirmCallback,
        openPromptModal,
        closePromptModal,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
}

export default ModalsContextProvider;
