import styled from 'styled-components';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../../constants';
import { desktopMediaQuery } from '../../../styleHelpers';
import { FaRegHeart } from 'react-icons/fa6';
import { textGlow } from '../../ImageStuff/animations';

interface P {
  bigger?: boolean;
  inLandingPage?: boolean;
}

const fakesImageSrc = 'OnlyFakesLogo.webp';

const Logo = ({ bigger, inLandingPage }: P) => {
  const isOnlyFakes = window.location.hostname === 'onlyfakes.app';
  if (inLandingPage) return <StyledHeartIcon bigger />;

  if (isOnlyFakes) {
    return (
      <Container bigger={bigger}>
        <LogoImage src={fakesImageSrc} alt="OnlyFakes Logo" bigger={bigger} />
      </Container>
    );
  }
  return (
    <Container bigger={bigger}>
      {bigger && <StyledHeartIcon />}
      <LogoText bigger={bigger}>UncensoredAI.io</LogoText>
    </Container>
  );
};

export default Logo;

const Container = styled.div<P>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${({ bigger }) => (bigger ? biggerFontSize : fontSize)}px;
`;

const LogoImage = styled.img<P>`
  // if bigger then 150px
  max-width: ${({ bigger }) => (bigger ? 150 : 100)}px;
`;

const StyledHeartIcon = styled(FaRegHeart)<P>`
  color: ${PRIMARY_COLOR};
  font-size: ${({ bigger }) => (bigger ? 64 : 42)}px;
  margin-bottom: ${({ bigger }) => (bigger ? -8 : 0)}px;

  ${desktopMediaQuery} {
    font-size: ${({ bigger }) => (bigger ? 100 : 48)}px;
  }
`;

const fontSize = 16;
const biggerFontSize = 32;

const LogoText = styled.div<P>`
  color: ${PRIMARY_TEXT_COLOR};
  font-family: 'comfortaa';

  animation: ${textGlow} 2s infinite;
`;
