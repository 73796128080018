import styled from 'styled-components';
import useSetProfileImage from '../../hooks/userHandling/useSetProfileImage';
import { EditButtonBase } from './EditButtonBase';
import LoadingIndicator from '../common/LoadingIndicator';

interface ImgProps {
  url?: string;
  showEditButton?: boolean;
  imageDetailVariant?: boolean;
  inHomePage?: boolean;
  smaller?: boolean;
}

export const UserAvatar = ({
  url = DEFAULT_PROFILE_IMAGE_URL,
  showEditButton,
  imageDetailVariant,
  inHomePage,
}: ImgProps) => {
  const { setProfileImage, loading } = useSetProfileImage();
  if (loading) return <LoadingIndicator differentBgColor />;
  const smaller = url === DEFAULT_PROFILE_IMAGE_URL;
  return (
    <ImageContainer
      imageDetailVariant={imageDetailVariant}
      inHomePage={inHomePage}
      smaller={smaller}
    >
      {showEditButton && <ChangeProfileImage onClick={setProfileImage} />}
      <Image src={url} />
    </ImageContainer>
  );
};

export const DEFAULT_PROFILE_IMAGE_URL = '/heart.png';

const ImageContainer = styled.div<ImgProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${(props) => {
    if (props.inHomePage) return '5vw';
    return props.imageDetailVariant ? '8vw' : '20vw';
  }};
  height: ${(props) => {
    if (props.inHomePage) return '5vw';
    return props.imageDetailVariant ? '8vw' : '20vw';
  }};
  max-width: ${(props) => {
    if (props.inHomePage) return '40px';
    return props.imageDetailVariant ? '75px' : '150px';
  }};
  max-height: ${(props) => {
    if (props.inHomePage) return '40px';
    return props.imageDetailVariant ? '75px' : '150px';
  }};
  // if smaller prop then set width and height to 40px
  max-width: ${(props) => props.smaller && '35px'};
  max-height: ${(props) => props.smaller && '35px'};
  padding: 8px;
  padding-top: 12px;
`;

const Image = styled.img`
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;

const ChangeProfileImage = styled(EditButtonBase)`
  position: absolute;
  top: -2px;
  right: -2px;
`;
