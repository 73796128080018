import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LIGHTER_GREY_COLOR } from '../../constants';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import useHandleUpdateImages from '../../hooks/images/useHandleUpdateImages';
import useGetImageDataWithTrackId from '../../hooks/misc/useGetImageDataWithTrackId';
import { FakeImage } from '../../types';
import LoadingAnimation from '../common/LoadingStuff/LoadingAnimation';
import LoadingBar from '../common/LoadingStuff/LoadingBar';
import { waitSeconds } from '../helpers';
import RandomTip from './RandomTip';
import { useImagesContext } from '../../context/ImagesContextProvider';
import useProgressTracking from '../../hooks/images/useProgressTracking';
import { desktopMediaQuery } from '../../styleHelpers';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';

interface P {
  image: FakeImage;
  inHistory?: boolean;
  hideRandomTip?: boolean;
  smallerForHomepage?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function sleep(seconds: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

export const PLACEHOLDER_IMAGE_PATH = '/placeholder.png';

const GeneratedImage = ({
  image,
  inHistory,
  hideRandomTip,
  smallerForHomepage,
}: P) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [_, setStateToForceUpdate] = useState(1);
  const {
    loading,
    setLoading,
    generationStartedTimestamp,
    generationReadyTimestamp,

    setShowSomethingWentWrong,
  } = useGeneralContext();
  const { amountToGenerate } = useImageGenerationSettingsContext();
  const { failedTrackIds, setFailedTrackIds } = useImagesContext();
  const getImageDataWithTrackId = useGetImageDataWithTrackId();
  const handleUpdateImages = useHandleUpdateImages();

  const [url, setUrl] = React.useState<string>(image.imageUrl);

  const [loaderToChoose, setLoaderToChoose] = React.useState(0);

  const { progress, timeLeft } = useProgressTracking(
    generationStartedTimestamp,
    generationReadyTimestamp,
    image.generating,
  );

  useEffect(() => {
    const updateUrl = async () => {
      if (image.failed) {
        return;
      }
      if (image.generating) {
        const amountOfLoaders = 8;
        const randomNumber = Math.floor(Math.random() * amountOfLoaders);
        setLoaderToChoose(randomNumber);

        if (image.isEnhanced) return;
        await waitSeconds(2);
        const newImgData = await getImageDataWithTrackId(image.trackId);

        if (newImgData === 'cancelled') {
          return;
        }

        if (!newImgData) {
          setFailedTrackIds((s) => [...s, image.trackId]);

          setShowSomethingWentWrong(true);
          setLoading(false);
          return;
        }
        newImgData.generating = false;
        setUrl(newImgData.imageUrl);
        handleUpdateImages(newImgData);
        setLoading(false);
      }
    };
    updateUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedTrackIds]);

  const maxHeight = image ? image.height : 512;
  const id = image ? image.trackId : 'id';

  useEffect(() => {
    const updateMaxHeightToId = () => {
      const image = document.getElementById(id);
      if (image) {
        // add maxHeight to images parent
        const parent = image.parentElement;
        if (parent) {
          parent.style.maxHeight = `${maxHeight}px`;
        }
      }
    };

    !inHistory && updateMaxHeightToId();
  }, [id, inHistory, maxHeight]);

  const shouldShowUseTurboTip =
    timeLeft > 1000 * 60 * 1.5 && !image.isEnhanced && amountToGenerate < 2;

  const waitSecondsBetweenCheckingIfImageIsReady = 10;

  return (
    <ImageContainer>
      <StyledImage
        src={url}
        alt=" "
        style={{
          width: '100%',
          height: '100%',
        }}
        // This hack is needed so the image when it's not ready on server actually loads when it is
        onError={async ({ currentTarget }) => {
          image.generating = true;
          currentTarget.onerror = null;
          setLoading(true);
          currentTarget.src = PLACEHOLDER_IMAGE_PATH;
          await sleep(waitSecondsBetweenCheckingIfImageIsReady);
          setStateToForceUpdate((s) => s + 1);
          currentTarget.src = url;

          image.generating = false;
          setLoading(false);
        }}
        onLoad={({ currentTarget }) => {
          //if src is PLACEHOLDER_IMAGE_PATH, then return early
          if (currentTarget.src === PLACEHOLDER_IMAGE_PATH) return;
          if (image.isEnhanced) {
            // setLoading(false);
          }
        }}
        smallerForHomepage={smallerForHomepage}
      />
      {!inHistory && loading && image.generating && (
        <LoadingContainer>
          {!hideRandomTip && <RandomTip />}
          <LoadingAnimation loading={loading} loaderToChoose={loaderToChoose} />
          <LoadingBar progress={progress} timeLeft={timeLeft} />
          {timeLeft < 1 && (
            <div style={{ marginTop: 8, fontSize: 10 }}>
              Please keep waiting a bit longer... sometimes the queue is long...
              Sorry!
            </div>
          )}
          {shouldShowUseTurboTip && <UseTurbo />}
        </LoadingContainer>
      )}
    </ImageContainer>
  );
};

const UseTurbo = () => (
  <div
    style={{
      marginTop: 8,
      fontSize: 10,
      color: LIGHTER_GREY_COLOR,
    }}
  >
    Hint: use Turbo mode to generate faster. Free credits by signing up
  </div>
);

const LoadingContainer = styled.div`
  position: absolute;
  top: 52%;
  left: 50%;
  width: 120px;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: ${LIGHTER_GREY_COLOR};
  pointer-events: none;
`;

interface StyledImageProps {
  smallerForHomepage?: boolean;
}

const StyledImage = styled.img<StyledImageProps>`
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  object-fit: contain;
  max-height: 420px;
  ${desktopMediaQuery} {
    max-height: 548px;
  }
  ${(props) =>
    props.smallerForHomepage &&
    `
    max-height: 380px;
    ${desktopMediaQuery} {
      max-height: 512px;
    }
  `}
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
`;

export default GeneratedImage;
