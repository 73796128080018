import { useState } from 'react';
import styled from 'styled-components';
import { Section, TitleTextWithGradient } from './CommonComponents';
import { SECONDARY_TEXT_COLOR } from '../../constants';

const FAQ = () => {
  return (
    <Section as="article">
      <Wrapper>
        <TitleTextWithGradient>
          Frequently asked questions
        </TitleTextWithGradient>
        <FAQItem
          title="How does the app generate images?"
          text="The app leverages advanced deep learning tech (Stable Diffusion), trained on billions of images to master various styles and nuances."
        />
        <FAQItem
          title="Do you save chat conversations?"
          text="No! We value the privacy of our users. Conversations are not stored or monitored."
        />
        <FAQItem
          title="I want to learn more"
          text="The internet is full of good tutorials, here's one in-depth that'll teach most what you need to know: https://www.youtube.com/watch?v=z-AoELaJfn0"
        />
        <FAQItem
          title="I have a problem!"
          text="Hit me up at hello@uncensoredAI.io. I'm based in Europe, so time zone differences might delay my reply."
        />
        <FAQItem
          title="How can I generate X?"
          text="I suggest just playing around and learning the ropes. If you're stuck, check out the Discord community for tips and tricks. "
        />
        <FAQItem
          title="Image quality issues?"
          text="Creating great images may take a few tries. Adjust your prompt, remove extra parentheses, or generate multiple images to get the right one. Use the editing tool for quick fixes. Need help? Our Discord community often has good advice"
        />
        <FAQItem
          title="What content is off-bounds?"
          text="Content resembling minors is not allowed, including Lolicon. No aging up underage characters. Do not share or create celebrity look-alike images. Breaking the rules will result in a ban"
        />
        <FAQItem
          title="Who's behind the site/app?"
          text="Built and run by yours truly, aka 'The Developer.' Your support fuels an indie developer from Finland. Before entrepreneurship, I worked in unicorn tech companies. Current reigning Employee of the Month: GPT-4."
        />
        <FAQItem
          title="Can I use the images commercially?"
          text="Sure, as long as the art is your original creation using our platform."
        />
      </Wrapper>
    </Section>
  );
};

export default FAQ;

const Wrapper = styled.div`
  max-width: 400px;

  text-align: start;

  padding: 8px;
`;

interface FAQComponentProps {
  title: string;
  text: string;
}

const FAQItemWrapper = styled.div`
  max-width: 400px;

  margin-bottom: 16px;
`;

const FAQItemTitle = styled.div`
  display: flex;
  cursor: pointer;

  justify-content: space-between;

  font-size: 18px;
`;

const FAQText = styled.p`
  display: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? 'block' : 'none')};
  font-size: 16px;
  color: ${SECONDARY_TEXT_COLOR};
`;

const FAQItem: React.FC<FAQComponentProps> = ({ title, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <FAQItemWrapper>
      <FAQItemTitle onClick={toggleOpen}>
        <div>{title}</div>
        <div>{isOpen ? '-' : '+'}</div>
      </FAQItemTitle>
      <FAQText isOpen={isOpen}>{text}</FAQText>
    </FAQItemWrapper>
  );
};
