import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { FakeImage } from '../../types';
import NonSharedImageFeedModal from './NonSharedImageFeedModal';
import NonSharedFeedImage from './NonSharedFeedImage';
import { getIsOnDesktop } from '../../styleHelpers';
import BulkDownloadSection from './BulkDownloadSection';

interface I {
  images: FakeImage[];
  inHistory?: boolean;
}

const NoImgs = () => <h3>No images...</h3>;

const FeedContainer = ({ images, inHistory }: I) => {
  const [items, setItems] = useState<FakeImage[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<FakeImage | null>(null);
  const [indexOfSelectedImage, setIndexOfSelectedImage] = useState<number>(0);
  const [selectedImages, setSelectedImages] = useState<FakeImage[]>([]);
  const [isBulkDownloadModeOn, setIsBulkDownloadModeOn] = useState(false);

  const windowWidth = window.innerWidth;
  const shouldUseScrollingFixes = windowWidth > 2400;
  const SHOW_X_PER = shouldUseScrollingFixes ? 25 : 10;
  const scrollThreshold = shouldUseScrollingFixes ? 0.2 : 0.8;

  useEffect(() => {
    setItems(images.slice(0, SHOW_X_PER));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  if (items.length === 0) return <NoImgs />;

  const fetchMore = () => {
    const nextItems = images.slice(items.length, items.length + SHOW_X_PER);

    setItems(items.concat(nextItems));
  };
  const hasMore = items.length < images.length;
  const dataLength = items?.length;

  const handleClickOnImage = (image: FakeImage, i: number) => {
    if (isBulkDownloadModeOn) {
      const isImageSelected = selectedImages.some(img => img.trackId === image.trackId);
      if (isImageSelected) {
        setSelectedImages(selectedImages.filter(img => img.trackId !== image.trackId));
      } else {
        setSelectedImages([...selectedImages, image]);
      }
      return;
    }

    setSelectedImage(image);
    setIndexOfSelectedImage(i);
    setIsModalOpen(true);
  };

  const isOnDesktop = getIsOnDesktop();

  const scrollAmountWhenShowingNextOrPreviousImage = isOnDesktop ? 72.5 : 145;
  const handleShowPreviousImage = () => {
    if (indexOfSelectedImage === 0) return;
    const previousImage = images[indexOfSelectedImage - 1];
    setSelectedImage(previousImage);
    setIndexOfSelectedImage(indexOfSelectedImage - 1);

    window.scrollTo(
      0,
      window.scrollY - scrollAmountWhenShowingNextOrPreviousImage,
    );
  };

  const handleShowNextImage = () => {
    if (indexOfSelectedImage === images.length - 1) return;
    const nextImage = images[indexOfSelectedImage + 1];
    setSelectedImage(nextImage);
    setIndexOfSelectedImage(indexOfSelectedImage + 1);

    window.scrollTo(
      0,
      window.scrollY + scrollAmountWhenShowingNextOrPreviousImage,
    );
  };

  return (
    <Container id="scrollableDiv">
      <NonSharedImageFeedModal
        showModal={isModalOpen}
        setShowModal={setIsModalOpen}
        image={selectedImage}
        handleShowPreviousImage={handleShowPreviousImage}
        handleShowNextImage={handleShowNextImage}
        inHistory={inHistory}
      />
      <BulkDownloadSection
        isBulkModeOn={isBulkDownloadModeOn}
        setIsBulkModeOn={setIsBulkDownloadModeOn}
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
      />

      <InfiniteScroll
        dataLength={dataLength}
        next={fetchMore}
        hasMore={hasMore}
        loader={<NoImgs />}
        scrollThreshold={scrollThreshold}
        style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: isOnDesktop
            ? 'repeat(4, 2fr)'
            : 'repeat(2, 1fr)',
          gridTemplateRows: isOnDesktop ? 'repeat(4, 2fr)' : 'repeat(2, 1fr)',
          gridGap: '1px',
          overflow: 'hidden',
        }}
      >
        {items?.map((image, i) => (
          <div onClick={() => handleClickOnImage(image, i)}>
            <NonSharedFeedImage
              key={image.trackId}
              image={image}
              isBulkModeOn={isBulkDownloadModeOn}
              isSelected={selectedImages.some(img => img.trackId === image.trackId)}
            />
          </div>
        ))}
      </InfiniteScroll>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow: auto;
  padding-bottom: 100px;
`;

export default FeedContainer;
