import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useChatContext } from '../../context/ChatContextProvider';

import { CHAT_CONVERSATION_PATH, FEED_PATH, HOME_PATH } from '../../pathNames';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import { StyledButton } from '../../components/common/StyledButton';
import { MdSearch } from 'react-icons/md';
import { FaMagic } from 'react-icons/fa';

const ChatsList = () => {
  const { chats, setActiveChat } = useChatContext();
  const navigate = useNavigate();

  const handleChatClick = (chatId: string) => {
    setActiveChat(chatId);
    navigate(CHAT_CONVERSATION_PATH);
  };

  const getLastMessage = (chatId: string) => {
    const chat = chats.find((chat) => chat.id === chatId);
    if (!chat) return 'N/A';

    const lastMessage = chat.messages[chat.messages.length - 1];
    if (!lastMessage) return 'N/A';

    return lastMessage;
  };

  const getPreviewForLastMessage = (chatId: string) => {
    const lastMessage = getLastMessage(chatId);
    if (lastMessage === 'N/A') return '...';

    const CHARACTERS_TO_SHOW = 25;
    // show only first 15 characters of the message
    const content = lastMessage.content.slice(0, CHARACTERS_TO_SHOW);

    return (
      content + (lastMessage.content.length > CHARACTERS_TO_SHOW ? '...' : '')
    );
  };

  const getTimestampForLastMessage = (chatId: string) => {
    const lastMessage = getLastMessage(chatId);
    if (lastMessage === 'N/A' || !lastMessage.timestamp) return '';

    const date = new Date(lastMessage.timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // sort the ones with timestamp first, and the newest timestamp, and then the rest
  const sortedChats = chats.sort((a, b) => {
    const lastMessageA = getLastMessage(a.id);
    const lastMessageB = getLastMessage(b.id);

    if (lastMessageA === 'N/A') return 1;
    if (lastMessageB === 'N/A') return -1;

    return (
      new Date(lastMessageB.timestamp).getTime() -
      new Date(lastMessageA.timestamp).getTime()
    );
  });

  if (sortedChats.length === 0) {
    return (
      <ListContainer>
        <h2>No chats yet</h2>
        <div>
          Start a new one by either creating an image or exploring
          community-made ones:
        </div>
        <StyledButton onClick={() => navigate(HOME_PATH)}>
          <FaMagic /> Create
        </StyledButton>
        <StyledButton onClick={() => navigate(FEED_PATH)}>
          <MdSearch /> Explore
        </StyledButton>
      </ListContainer>
    );
  }

  return (
    <ListContainer>
      <h2>Your Chats ❤️</h2>
      {sortedChats.map((chat) => (
        <ChatItem key={chat.id} onClick={() => handleChatClick(chat.id)}>
          <Avatar src={chat.activeImage} alt={chat.botName} />
          <ChatInfo>
            <ChatHeader>
              <ChatName>{chat.botName}</ChatName>
              <ChatTimestamp>
                {getTimestampForLastMessage(chat.id)}
              </ChatTimestamp>
            </ChatHeader>
            <ChatSnippet>{getPreviewForLastMessage(chat.id)}</ChatSnippet>
          </ChatInfo>
        </ChatItem>
      ))}
    </ListContainer>
  );
};

export default ChatsList;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95vw;
  max-width: 600px;
  background-color: ${BACKGROUND_COLOR};
  overflow-y: auto;
  height: 100vh;
  padding-bottom: 90px;
  gap: 4px;
`;

const ChatItem = styled.div`
  display: flex;
  align-items: center;
  width: 95%;

  padding: 16px;
  cursor: pointer;
  border-bottom: 0.5px dotted ${BORDER_COLOR};

  transition: scale 0.2s ease-in-out;

  &:hover {
    scale: 1.02;
  }

  ${desktopMediaQuery} {
    width: 80%;
  }
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
`;

const ChatInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatName = styled.span`
  font-weight: bold;
  color: ${PRIMARY_TEXT_COLOR};
  font-size: 16px;
`;

const ChatTimestamp = styled.span`
  font-size: 12px;
  color: ${SECONDARY_TEXT_COLOR};
`;

const ChatSnippet = styled.span`
  margin-top: 4px;
  font-size: 14px;
  color: ${SECONDARY_TEXT_COLOR};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
