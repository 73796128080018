import { Dispatch, SetStateAction } from 'react';

import Switch from 'react-switch';
import styled from 'styled-components';
import { BORDER_COLOR, PRIMARY_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import { FaUserCheck } from 'react-icons/fa6';

interface TabBarProps {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
}

const ToggleSwitchInMagicMode = ({ value, setValue }: TabBarProps) => {
  const onChange = async () => {
    /*
    if (loggedInUser?.type !== 'gold') {
      toast.error('Get Gold Subscription ⭐ to use this feature');
      await waitSeconds(1)
      setGoldModalOpen(true);
      return;
    }
      */
    setValue((b) => !b);
  };
  return (
    <OuterContainer>
      <TitleText>
        <FaUserCheck />
        Person
      </TitleText>
      <ToggleContainer>
        <TagButtonInToggle
          onClick={() => setValue(true)}
          isSelected={value === false}
        >
          New Face
        </TagButtonInToggle>
        <SwitchStyled
          onChange={onChange}
          checked={value}
          checkedIcon={false}
          uncheckedIcon={false}
          onColor="#888"
          onHandleColor={PRIMARY_COLOR}
          offHandleColor={PRIMARY_COLOR}
        />
        <TagButtonInToggle
          onClick={() => setValue(false)}
          isSelected={value === true}
        >
          Same Face
        </TagButtonInToggle>
      </ToggleContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding-left: 8px;

  ${desktopMediaQuery} {
    width: 500px;
    margin-left: 385px;
  }
`;

const TitleText = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;

  gap: 4px;
  margin-bottom: -4px;
  margin-left: 4px;
`;

const TagButtonInToggle = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  border-radius: 4px;

  transition: 0.3s;

  min-width: 20vw;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;

  margin-bottom: -2px;

  font-size: 16px;
  height: 28px;
  white-space: nowrap;

  border: 1px solid
    ${({ isSelected }) => (isSelected ? PRIMARY_COLOR : BORDER_COLOR)};

  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      border: 1px solid ${PRIMARY_COLOR};
    }
  }
`;

const ToggleContainer = styled.label`
  display: flex;
  flex-direction: row;

  margin-top: 16px;
  margin-bottom: 4px;
  margin-left: 0px;
  margin-right: 4px;

  font-weight: bold;
  font-size: 16px;
`;

const SwitchStyled = styled(Switch)`
  margin-top: 2px;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 8px;
  width: 65px;
`;
export default ToggleSwitchInMagicMode;
