import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import ImageChannelTag from './FeedImagePage/ImageChannelTag';
import { desktopMediaQuery } from '../../styleHelpers';
import ChannelsMenuModal from './ChannelsMenuModal/ChannelsMenuModal';
import { FeedChannel } from '../../types';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { useModalsContext } from '../../context/ModalsContextProvider';
import { FaThLarge } from 'react-icons/fa';

type ChannelsListInFeedTopbarProps = {
  channels: FeedChannel[];
};

const ChannelsListInFeedTopbar: React.FC<ChannelsListInFeedTopbarProps> = ({
  channels,
}) => {
  const {
    showChannelListModal: showModal,
    setShowChannelListModal: setShowModal,
  } = useModalsContext();
  const { selectedChannel } = useImagesContext();

  const ScrollContainerRef = useRef<HTMLDivElement>(null);

  const filteredChannels = channels.filter(
    (channel) => channel.imageCount !== 0,
  );

  // useMemo hook to compute channelNames based on selectedChannel and channels list.
  // 'all' is added at the second place. First is the selected channel and the rest, excluding the selected channel from the list again.
  const channelNames = useMemo(() => {
    const names = filteredChannels.map((channel) => channel.name);
    names.unshift('all');
    if (!selectedChannel) return names;

    if (ScrollContainerRef?.current) {
      ScrollContainerRef.current.scrollLeft = 0;
    }

    return [
      selectedChannel,
      ...names.filter((name) => name !== selectedChannel),
    ];
  }, [channels, selectedChannel]);

  const handleClickShowAllChannels = () => {
    setShowModal(true);
  };

  if (channelNames.length === 0) return null;
  return (
    <OuterContainer>
      <Container ref={ScrollContainerRef}>
        {channelNames.map((channel) => (
          <ImageChannelTag
            key={channel}
            channelName={channel}
            isChannelSelected={channel === selectedChannel}
          />
        ))}
      </Container>
      <ShowAllChannelsButton onClick={handleClickShowAllChannels} />

      <ChannelsMenuModal
        channels={filteredChannels}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 95vw;
  overflow-x: auto;
  gap: 2px;

  scrollbar-width: none;

  ${desktopMediaQuery} {
    max-width: 78vw;
    margin-left: 5vw;
  }
`;

const ShowAllChannelsButton = styled(FaThLarge)`
  color: white;
  font-size: 28px;

  margin-left: 8px;
  margin-right: 4px;

  z-index: 2;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  ${desktopMediaQuery} {
    font-size: 40px;
  }
`;

export default ChannelsListInFeedTopbar;
