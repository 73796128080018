import styled from 'styled-components';
import { desktopMediaQuery } from '../../styleHelpers';
import { ExampleImage } from './CommonComponents';
import { PRIMARY_COLOR_DIMMED } from '../../constants';

const SEOSection = () => {
  return (
    <ResponsiveContainer>
      <ExampleImage src={'firstImageOnLanding.webp'} />
      <CardContainer>
        <CardTitle>
          UncensoredAI.io ❤️ <br />
          Your Free, Unrestricted AI App
        </CardTitle>
        <CardText>
          Explore our free, unfiltered AI Image Generator and AI Chat. Create
          stunning AI-generated images and chat with AI characters — no sign-up
          required! Enjoy the freedom of unrestricted AI, designed for limitless
          creativity and seamless sharing
        </CardText>
      </CardContainer>
    </ResponsiveContainer>
  );
};

export default SEOSection;

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 650px;

  ${desktopMediaQuery} {
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
    max-width: 80vw;
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 70vw;
  max-width: 450px;
  padding: 24px;

  background-color: none;
  border-radius: 8px;

  box-shadow: 2px 2px 4px ${PRIMARY_COLOR_DIMMED};
`;

const CardTitle = styled.h1`
  text-align: start;
  width: 100%;
  font-size: 24px;
  margin-top: 0;
`;

const CardText = styled.div`
  li {
    list-style-type: none;
    margin-bottom: 8px;
  }
`;
