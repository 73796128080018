import styled from 'styled-components';
import { SmallStyledButton } from '../../components/common/StyledButton';
import { FakeImage } from '../../types';
import { useHandleDownloadImage } from '../../hooks/images/useHandleDownloadImage';

interface BulkDownloadSectionProps {
  isBulkModeOn: boolean;
  setIsBulkModeOn: (value: boolean) => void;
  selectedImages: FakeImage[];
  setSelectedImages: (images: FakeImage[]) => void;
}

// TODO;: DOUBLE CHECK THAT THIS WORKS
function BulkDownloadSection({
  isBulkModeOn,
  setIsBulkModeOn,
  selectedImages,
  setSelectedImages,
}: BulkDownloadSectionProps) {
  const downloadImage = useHandleDownloadImage();

  const handleBulkDownload = async () => {
    for (const image of selectedImages) {
      await downloadImage(image);
    }
    setSelectedImages([]);
    setIsBulkModeOn(false);
  };

  return (
    <NonFixedContainer>
      {!isBulkModeOn ? (
        <NonFixedContainer>
          <SmallStyledButton onClick={() => setIsBulkModeOn(true)}>
            Bulk Download
          </SmallStyledButton>
        </NonFixedContainer>
      ) : (
        <ButtonGroup>
          <SmallStyledButton
            onClick={handleBulkDownload}
            disabled={selectedImages.length === 0}
          >
            Download {selectedImages.length} Images
          </SmallStyledButton>
          <SmallStyledButton onClick={() => setIsBulkModeOn(false)}>
            Cancel Bulk Download
          </SmallStyledButton>
        </ButtonGroup>
      )}
    </NonFixedContainer>
  );
}

const NonFixedContainer = styled.div`
  z-index: 100;
  padding: 8px;
`;

const ButtonGroup = styled.div`
  position: fixed;
  top: 80px;
  right: 36px;
  z-index: 100;
  padding: 8px;
  display: flex;
  gap: 8px;
`;

export default BulkDownloadSection;
