import { useLoggedInUserContext } from '../context/LoggedInUserContextProvider';
import { customFetch } from './customFetch';

const useCreateRedirectUrlForEmerchantpay = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const createRedirectUrlForEmerchantpay = async (purchaseType: string) => {
    const functionName = 'createRedirectUrlForEMerchantPay';

    const userId = loggedInUser?.id;

    if (!userId) {
      alert('You must be logged in to buy credits');
      return;
    }

    const { redirectUrl } = await customFetch(functionName, {
      purchaseType,
      userId,
    });

    return redirectUrl;
  };

  return createRedirectUrlForEmerchantpay;
};

export default useCreateRedirectUrlForEmerchantpay;
