import { useModalsContext } from "../../../context/ModalsContextProvider";

const useModalPrompt = () => {
  const { openPromptModal, closePromptModal } = useModalsContext();

  return (message: string) => {
    console.log('useModalPrompt', message);
    return new Promise<string | null>((resolve) => {
      openPromptModal(message, (inputValue) => {
        resolve(inputValue);  // This resolves the Promise with user input
        closePromptModal();
      });
    });
  };
};

export default useModalPrompt;