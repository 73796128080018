import React from 'react';
import styled from 'styled-components';
import TransitioningModal from '../../components/Modals/TransitioningModal';
import { BACKGROUND_COLOR } from '../../constants';
import { CloseButtonInModal } from '../../components/common/CloseButtonInModal';
import { FaTrash } from 'react-icons/fa6';
import { AiOutlinePlusSquare, AiOutlineSave } from 'react-icons/ai';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { removeImageFromLocalStorage } from '../../localStorage/imageStorage';
import { useHandleDownloadImage } from '../../hooks/images/useHandleDownloadImage';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { toast } from 'react-toastify';
import SaveImageMenuItem from '../../components/SaveImage/SaveImageMenuItem';

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowMoreMenuModalInHomeGenerator = ({ show, setShow }: Props) => {
  const { loading, images, setImages, activeImage, setLoading } =
    useGeneralContext();
  const { loggedInUser } = useLoggedInUserContext();
  const { setImageToBePosted } = useImagesContext();
  const { setShowCreateNewPostModal } = useGeneralContext();

  const handleDownloadImage = useHandleDownloadImage();

  const handleDelete = () => {
    const trackIdOfActiveImage = activeImage.trackId;
    const confirmRemove = window.confirm(
      'Are you sure you want to remove this photo?',
    );
    if (!confirmRemove) {
      return;
    }

    if (images.length === 0 && !activeImage) {
      return;
    }
    if (loading) return;

    setImages((prevImages) =>
      prevImages.filter((image) => image.trackId !== trackIdOfActiveImage),
    );

    setLoading(false);
    removeImageFromLocalStorage(activeImage);
    setShow(false);
  };

  const handleDownload = () => {
    handleDownloadImage(activeImage);
    setShow(false);
  };

  const handleCreatePost = () => {
    if (!loggedInUser) {
      toast.error('You need to be logged in to share images');
      return;
    }
    if (activeImage?.sharedImageRefId || activeImage?.remixing) {
      toast.error('You can only post your original images');
      return;
    }
    activeImage && setImageToBePosted(activeImage);
    setShowCreateNewPostModal(true);
    setShow(false);
  };

  const closeModal = () => {
    setShow(false);
  };

  if (!show) return null;

  return (
    <TransitioningModal
      isOpen={show}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 200,
          height: '100%',
          width: '100%',
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: '16px',
          width: '92%',
          position: 'absolute',
          bottom: '0',
          border: 'none',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={() => setShow(false)}
    >
      <CloseButtonInModal onClick={() => setShow(false)} />
      <ContainerForMenuItems>
        <MenuItem onClick={handleDelete}>
          <FaTrash />
          Delete
        </MenuItem>
        <MenuItem onClick={handleDownload}>
          <AiOutlineSave />
          Save to your device
        </MenuItem>
        <MenuItem onClick={handleCreatePost}>
          <AiOutlinePlusSquare />
          Create a post
        </MenuItem>
        <SaveImageMenuItem onSave={closeModal} />
      </ContainerForMenuItems>
    </TransitioningModal>
  );
};

const ContainerForMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 12px;
  cursor: pointer;
  gap: 16px;
  width: 100%;
  padding: 8px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
`;

export default ShowMoreMenuModalInHomeGenerator;
