import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BACKGROUND_COLOR } from '../../constants';
import GenerateButton from '../AdvancedModeTab/ButtonsBelowImage/GenerateButton';
import useHandleSendPrompt from '../../hooks/images/useHandleSendPrompt';
import { desktopMediaQuery } from '../../styleHelpers';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { getDefaultStyleViaId } from '../../utils/imageStylesHelpers';
import { waitSeconds } from '../../components/helpers';
import { TabInCreateCharacter } from '../../types';
import { fadeIn } from '../../components/ImageStuff/animations';
import SpeedModeSelector from '../AdvancedModeTab/ButtonsBelowImage/SpeedModeSelector';
import { toast } from 'react-toastify';
import { getEngineUsingModelId } from '../../utils/engineHelpers';
import StrongPrimaryButton from '../../components/common/StrongPrimaryButton';

interface P {
  activeTab: TabInCreateCharacter;
  setActiveTab: (tab: TabInCreateCharacter) => void;
  handleTabChange: () => void;
}

// TODO: refactor all the logic out from here! Maybe to backend?
const StickyButtonsOnBottomInMagic = ({
  handleTabChange,
  activeTab,
  setActiveTab,
}: P) => {
  const { selectedTags, speedMode, setSpeedMode } =
    useImageGenerationSettingsContext();

  const handleSendPrompt = useHandleSendPrompt({
    speedMode,
    isSeedFrozen: false,
    amountToGenerate: 1,
    cfg: 7,
  });

  const { images } = useGeneralContext();

  // this needs to go in a hook or something
  const generatePromptToSend = () => {
    const prePrompt = `RAW photo, woman, `;
    const afterPrompt = `8k uhd, dslr, soft lighting, high quality, film grain, Fujifilm XT3, hasselblad, gigapixel, detailed hair, detailed body, (detailed face and eyes:1.2), (depth of field:0.6), Leica, 8K HDR, High contrast, shadows, bokeh`;

    const negativeStylePrompt = `(deformed face:1.2), ((disfigured)), ((bad art)), ((deformed)), (disfigured face and eyes:1.2), (non-symmetrical eyes:1.1) (digital:1.2), (digital render:1.2) (photoshop:1.1), (deformed iris, deformed pupils, semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime, mutated hands and fingers:1.4), (deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, disconnected limbs, mutation, mutated, ugly, disgusting, amputation`;

    const agePart =
      selectedTags.find((tag) => tag.subcategoryId === 'age')?.prompt || '';
    const originPart =
      selectedTags.find((tag) => tag.subcategoryId === 'origin')?.prompt || '';
    const hairColorPart =
      selectedTags.find((tag) => tag.subcategoryId === 'hair-color')?.prompt ||
      '';
    const hairStyle = selectedTags
      .filter((tag) => tag.subcategoryId === 'hair-style')
      .map((tag) => tag.prompt)
      .join(', ');
    const face =
      selectedTags.find((tag) => tag.subcategoryId === 'face')?.prompt || '';
    const bodyType =
      selectedTags.find((tag) => tag.subcategoryId === 'body-type')?.prompt ||
      '';
    const boobs =
      selectedTags.find((tag) => tag.subcategoryId === 'boobs')?.prompt || '';
    const boobsNegative =
      selectedTags.find((tag) => tag.subcategoryId === 'boobs')
        ?.negativePrompt || '';
    const clothes = selectedTags
      .filter((tag) => tag.subcategoryId === 'clothing')
      .map((tag) => tag.prompt)
      .join(', ');
    const clothesNegative = selectedTags
      .filter((tag) => tag.subcategoryId === 'clothing')
      .map((tag) => tag.negativePrompt)
      .join(', ');

    // composition
    const view =
      selectedTags.find((tag) => tag.subcategoryId === 'view')?.prompt || '';
    const viewNegative =
      selectedTags.find((tag) => tag.subcategoryId === 'view')
        ?.negativePrompt || '';

    // place
    const scene =
      selectedTags.find((tag) => tag.subcategoryId === 'scene')?.prompt || '';

    // action
    const action =
      selectedTags.find((tag) => tag.subcategoryId === 'action')?.prompt || '';

    /*
    const butt =
      selectedTags.find((tag) => tag.subcategoryId === 'butt')?.prompt || '';
      */

    const formatPart = (
      part: string,
      prefix: string = '',
      suffix: string = '',
    ) => (part ? `${prefix}${part}${suffix}` : '');

    const fullPrompt = [
      prePrompt,
      formatPart(view, '', ' '),
      formatPart(agePart, '', ' '),
      formatPart(originPart, 'from ', ', '),
      formatPart(hairStyle, '', ' '),
      formatPart(hairColorPart, '', ', '),
      formatPart(face, '', ', '),
      formatPart(bodyType, '', ', '),
      formatPart(boobs, '', ', '),
      formatPart(clothes, '(', '), '),
      formatPart(action, '', ', '),
      formatPart(scene, 'in ', ', '),
      afterPrompt,
    ]
      .filter(Boolean)
      .join('')
      .trim();

    const negativePrompt =
      clothes.includes('nude') || clothes.includes('(Topless)')
        ? `${boobsNegative} (disfigured vagina:1.1)`
        : `${boobsNegative} (topless:1.2)`;

    const formatNegativePart = (part: string, suffix: string = ', ') =>
      part ? `${part}${suffix}` : '';

    const finalNegativePrompt = [
      negativePrompt,
      viewNegative,
      clothesNegative,
      negativeStylePrompt,
    ]
      .map((part) => formatNegativePart(part))
      .join('')
      .slice(0, -2); // Remove the last ", "

    return {
      fullPrompt,
      negativePrompt: finalNegativePrompt,
    };
  };

  // TODO: move these two Generate page rather, or hook (yeah do a hook)
  const handleClickGenerate = async () => {
    if (selectedTags.length === 0) {
      toast.error('Please select at least one tag before generating 🏷️');
      return;
    }
    // TODO: figure out a cleaner way to do this shit. maybe rewrite whole sendPrompt thing
    const { fullPrompt, negativePrompt } = generatePromptToSend();
    // TODO: dont have style here
    const noneStyle = getDefaultStyleViaId('0');
    const denoisingStrength = 0.85;
    const realisticHD2 = getEngineUsingModelId('realistic_vision_v5.1');
    handleSendPrompt(
      fullPrompt,
      negativePrompt,
      noneStyle,
      denoisingStrength,
      realisticHD2,
    );
    setActiveTab('base');
  };

  const [userHasScrolled, setUserHasScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setUserHasScrolled(true);
      // Remove the event listener once scrolling is detected
      window.removeEventListener('scroll', handleScroll);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array to run the effect once on mount

  const shouldShowNext = activeTab !== 'other';
  const shouldChangeToGOld = !shouldShowNext;

  const handlePressNext = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();

    // TODO: add so it hops to next subcategory..
    handleTabChange();
    if (activeTab === 'head') {
      await waitSeconds(0.1);
    }
    const topAmount = images.length === 0 ? 420 : 720;

    window.scrollTo({
      top: topAmount,
      behavior: 'smooth',
    });
  };
  return (
    <Container shouldShowNext={shouldShowNext}>
      {userHasScrolled && (
        <>
          <SpeedModeContainer>
            <SpeedModeSelector
              speedMode={speedMode}
              setSpeedMode={setSpeedMode}
            />
          </SpeedModeContainer>
          <GenButtonContainer>
            {shouldShowNext && (
              <GoToNextButton onClick={handlePressNext}>Next</GoToNextButton>
            )}

            <GenerateButton
              handleSendPrompt={handleClickGenerate}
              speedMode={speedMode}
              inCreateCharacter
              shouldChangeToGold={shouldChangeToGOld}
            />
          </GenButtonContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div<{ shouldShowNext: boolean }>`
  display: flex;
  flex-direction: row;
  position: fixed;
  //background-color: ${BACKGROUND_COLOR};
  width: 96%;
  bottom: ${({ shouldShowNext }) => (shouldShowNext ? '90px' : '80px')};
  left: 6px;

  ${desktopMediaQuery} {
    bottom: 16px;
    left: 35vw;
  }

  align-items: center;

  z-index: 2;

  transition: 0.3s;
`;

const SpeedModeContainer = styled.div`
  display: flex;
  margin-bottom: 16px;

  margin-right: -12px;

  width: 100px;

  ${desktopMediaQuery} {
    width: 150px;
  }
`;

const GenButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 98%;
  max-width: 400px;

  ${desktopMediaQuery} {
    max-width: 600px;
  }

  animation: ${fadeIn} 0.5s ease-in-out;
`;

const GoToNextButton = styled(StrongPrimaryButton)`
  margin-bottom: -4px;

  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      transform: translate(0, -2px);
      transition: transform 0.2s;
    }
  }
`;

export default StickyButtonsOnBottomInMagic;
