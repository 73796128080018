import styled, { keyframes } from 'styled-components';
import { FakeImage, GenericSetState, InfoText } from '../../types';
import { AiOutlineInfoCircle, AiOutlinePlusSquare } from 'react-icons/ai';
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { CloseButtonInFeedModal } from '../../components/common/CloseButtonInFeedModal';
import SaveButton from '../../components/common/SaveButton';
import { DESKTOP_WIDTH, MAX_WIDTH } from '../../constants';
import { useHandleDownloadImage } from '../../hooks/images/useHandleDownloadImage';
import { useNavigate } from 'react-router-dom';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { useEffect, useState } from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { AiOutlineSave } from 'react-icons/ai';
import { removeImageFromLocalStorage } from '../../localStorage/imageStorage';
import { FiTrash } from 'react-icons/fi';
import { RemixButton } from '../../components/common/RemixButton';
import { IconButton } from '../../components/common/IconButtonInFeed';
import InfoModal from '../../components/Modals/ImageInfoModal';
import { InpaintingButtonInModal } from '../../components/common/InpaintingButtonInModal';
import { INPAINTING_PATH } from '../../pathNames';
import { desktopMediaQuery } from '../../styleHelpers';
import { ModalThatTransitionsFromBottom } from '../../components/ImageStuff/animations';
import { ModalContent } from '../../components/common/sharedComponentsInFeeds';
import { toast } from 'react-toastify';
import useHandleClickRemix from '../../hooks/images/useHandleClickRemix';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import useStartChat from '../../hooks/chat/useStartChat';
import { FaCommentDots } from 'react-icons/fa6';

interface I {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
  image: FakeImage | null;
  handleShowPreviousImage: () => void;
  handleShowNextImage: () => void;

  inHistory?: boolean;
}

const NonSharedImageFeedModal = ({
  showModal,
  setShowModal,
  image,
  handleShowPreviousImage,
  handleShowNextImage,
  inHistory,
}: I) => {
  const handleDownloadImage = useHandleDownloadImage();
  const navigate = useNavigate();
  const { loggedInUser } = useLoggedInUserContext();

  const { setActiveImage, setShowCreateNewPostModal } = useGeneralContext();

  const { setImageToBePosted } = useImagesContext();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleRemixingImage = useHandleClickRemix();

  const handleDownload = () => {
    image && handleDownloadImage(image);
  };

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalText, setInfoModalText] = useState<InfoText | undefined>(
    undefined,
  );
  const showImageInfoModal = (info: InfoText) => {
    if (image?.selectedTags && image?.selectedTags.length > 0) {
      toast.error('Info not available for Magic Mode images, check back later');
      return;
    }

    if (image?.isInpainted) {
      toast.error('Info not available for inpainted images');
      return;
    }

    if (!!image?.uploadedImageUrl) {
      toast.error('Info not available on variation images');
      return;
    }
    setInfoModalText(info);
    setShowInfoModal(true);
  };

  const handleShowImageInfo = () => {
    if (!image) return;
    const { prompt, negativePrompt, engine, guidanceScale, seed, style } =
      image;
    const info = {
      prompt,
      negativePrompt,
      engine: engine.name,
      style: style?.name,
      seed,
      guidanceScale,
    };

    showImageInfoModal(info);
  };

  const handleShareImage = () => {
    if (!loggedInUser) {
      toast.error('You need to be logged in to share images');
      return;
    }
    if (image?.sharedImageRefId) {
      toast.error('You can only post your original images');
      return;
    }
    image && setImageToBePosted(image);
    setShowCreateNewPostModal(true);
    handleClose();
  };

  const handleRemoveFromHistory = () => {
    const areTheySure = window.confirm(
      'Are you sure you want to remove this image from history? (refresh to see changes)',
    );
    if (!areTheySure) return;

    image && removeImageFromLocalStorage(image);
  };

  const [positionY, setPositionY] = useState(1);
  const [positionX, setPositionX] = useState(0);
  const [swipeDirection, setSwipeDirection] = useState<
    'horizontal' | 'vertical' | null
  >(null);

  const handlers = useSwipeable({
    onSwipedLeft: () => handleShowNextImage(),
    onSwipedRight: () => handleShowPreviousImage(),
    onSwipedDown: () => handleClose(),
    trackMouse: true,
    onSwiping: (eventData: SwipeEventData) => {
      eventData.event.preventDefault();

      if (!swipeDirection) {
        setSwipeDirection(
          Math.abs(eventData.deltaX) > Math.abs(eventData.deltaY)
            ? 'horizontal'
            : 'vertical',
        );
      }

      setPositionX((prev) => prev + eventData.deltaX * 0.05);

      if (swipeDirection === 'horizontal') {
        setPositionX((prev) => prev + eventData.deltaX * 0.2);
      }

      if (swipeDirection === 'vertical') {
        setPositionY((prev) => Math.max(prev + eventData.deltaY * 0.3, 0));
      }
    },
    onSwiped: () => resetAll(),
    // change delta to make swipe shorter or longer
    delta: 10,
  });

  useEffect(() => {
    const handleKeyUp = (e: any) => {
      if (e.key === 'ArrowLeft') {
        handleShowPreviousImage();
      }
      if (e.key === 'ArrowRight') {
        handleShowNextImage();
      }
      if (e.key === 'Escape' || e.key === 'ArrowDown') {
        e.preventDefault();
        handleClose();
      }
    };

    // Add event listener on "componentDidMount"
    document.addEventListener('keyup', handleKeyUp);

    // Return a cleanup function to be run on "componentWillUnmount"
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleShowNextImage, handleShowPreviousImage]);

  const resetAll = () => {
    setPositionX(0);
    setPositionY(1);
    setSwipeDirection(null);
  };

  //always when active image changes, reset positions
  useEffect(() => {
    resetAll();
  }, [image]);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      // when component unmounts, restore the overflow property
      document.body.style.overflow = 'unset';
    };
  }, [showModal]);

  const handleInpainting = () => {
    image && setActiveImage(image);
    navigate(INPAINTING_PATH);
  };

  const { startChatWithFakeImage } = useStartChat();
  const handleStartChat = () => {
    image && startChatWithFakeImage(image);
    handleClose();
  };
  if (!image) return null;

  const isSmallHeight = window.innerHeight < 800;

  const isOnMobile = window.innerWidth < DESKTOP_WIDTH - 210;

  return (
    <ModalThatTransitionsFromBottom
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          zIndex: 100,
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
        },
        content: {
          display: 'flex',
          flexDirection: 'column',

          marginTop: isSmallHeight ? '5vh' : '8vh',
          left: '0',
          right: '0',
          bottom: '0',
          background: 'transparent',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '0',
          outline: 'none',
          padding: '0',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ModalContent
        {...handlers}
        style={{
          transform: `translate(${positionX}px, ${positionY}px)`,
        }}
      >
        <InfoModal
          showModal={showInfoModal}
          setShowModal={setShowInfoModal}
          infoText={infoModalText}
        />

        <CloseButtonInFeedModal onClick={handleClose} />

        <StyledImage key={image.imageUrl} src={image.imageUrl} />
      </ModalContent>

      <ChatIconContainer onClick={handleStartChat}>
        <FaCommentDots size={iconSize} />
        Chat
      </ChatIconContainer>
      <BottomButtonsContainer inHistory={inHistory} isOnMobile={isOnMobile}>
        {inHistory && (
          <IconContainer onClick={handleRemoveFromHistory}>
            <TrashButton />
          </IconContainer>
        )}

        <IconContainer onClick={handleInpainting}>
          <InpaintingButtonInModal inFeed />
        </IconContainer>

        <IconContainer onClick={handleDownload}>
          <DownloadButton />
        </IconContainer>

        <IconContainer onClick={handleShareImage}>
          <ShareButton />
        </IconContainer>

        <IconContainer onClick={() => handleRemixingImage(image)}>
          <RemixButton />
        </IconContainer>

        <IconContainer onClick={handleShowImageInfo}>
          <InfoButton />
        </IconContainer>

        <IconContainer>
          <IconButton>
            <SaveButton image={image} smaller inModal />
          </IconButton>
        </IconContainer>
      </BottomButtonsContainer>

      {!isOnMobile && (
        <ForwardBackButtonContainer>
          <GoToPreviousImageButton onClick={handleShowPreviousImage} />
          <GoToNextImageButton onClick={handleShowNextImage} />
        </ForwardBackButtonContainer>
      )}
    </ModalThatTransitionsFromBottom>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

// TODO: make both feeds into one component!!
const dekstopMaxWidth = 700;

const StyledImage = styled.img`
  object-fit: contain;
  max-width: ${MAX_WIDTH}px;
  width: 95%;
  height: auto;
  align-self: center;

  @media (min-width: ${DESKTOP_WIDTH}px) {
    margin-top: 8px;
    max-width: ${dekstopMaxWidth}px;
    max-height: 70vh;
  }

  animation: ${fadeIn} 0.2s ease-in;
`;

const ChatIconContainer = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  bottom: 16vh;
  right: 2vw;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  padding: 8px;
  ${desktopMediaQuery} {
    bottom: 19vh;
    right: calc((100vw - ${MAX_WIDTH}px) / 2 + 40px);
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
`;

const BottomButtonsContainer = styled.div<{
  inHistory?: boolean;
  isOnMobile: boolean;
}>`
  z-index: 110;
  position: absolute;
  // 8vh if not on mobile, 2 if on mobile
  bottom: ${({ isOnMobile }) => (isOnMobile ? '2vh' : '8vh')};
  right: calc((100vw - ${MAX_WIDTH}px) / 2 + 70px);
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  //if in history, move buttons to left
  ${({ inHistory }) =>
    inHistory &&
    `
    right: calc((100vw - ${MAX_WIDTH}px) / 2 + 30px);
    @media (max-width: 390px) {
      right: calc((100vw - ${MAX_WIDTH}px) / 2 + 40px);
    }
    @media (max-width: 375px) {
      right: calc((100vw - ${MAX_WIDTH}px) / 2 + 52px);
    }
  `}
`;

const IconContainer = styled.div`
  padding: 4px;
  @media (max-width: 390px) {
    padding: 3px;
  }
  @media (max-width: 375px) {
    padding: 2px;
  }
  @media (max-width: 360px) {
    padding: 1px;
  }
  @media (max-width: 345px) {
    padding: 0px;
  }
`;

interface ButtonProps {
  onClick: () => void;
}

const iconSize = 26;
const ShareButton = () => (
  <IconButton>
    <AiOutlinePlusSquare size={iconSize} />
  </IconButton>
);

const TrashButton = () => (
  <IconButton>
    <FiTrash size={iconSize} />
  </IconButton>
);

const InfoButton = () => (
  <IconButton>
    <AiOutlineInfoCircle size={iconSize} />
  </IconButton>
);

const DownloadButton = () => (
  <IconButton>
    <AiOutlineSave size={iconSize * 1} />
  </IconButton>
);

const ForwardBackButtonContainer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;

const BottomButton = styled.div`
  color: white;
  ${desktopMediaQuery} {
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      cursor: pointer;
    }
  }
  font-size: 40px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5%;
`;

const GoToPreviousImageButton = ({ onClick }: ButtonProps) => (
  <BottomButton onClick={onClick}>
    <IoMdArrowBack />
  </BottomButton>
);

const GoToNextImageButton = ({ onClick }: ButtonProps) => (
  <BottomButton onClick={onClick}>
    <IoMdArrowForward />
  </BottomButton>
);

export default NonSharedImageFeedModal;
