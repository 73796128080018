import styled from 'styled-components';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import { comeUpAnimation } from '../../components/ImageStuff/animations';

const TitlesSection = () => (
  <ContainerForPageTitles>
    <Title />
    <SubTitle />
  </ContainerForPageTitles>
);

const ContainerForPageTitles = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: 700px;
  margin-bottom: 8px;
`;

const Title = () => (
  <TitleContainer>Uncensored AI Image Generator & AI Chat</TitleContainer>
);

const TitleContainer = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  width: 100%;

  ${desktopMediaQuery} {
    font-size: 50px;
    line-height: 62px;
    text-align: center;
  }

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    ${PRIMARY_TEXT_COLOR} 20%,
    ${PRIMARY_COLOR} 80%
  );
  margin-bottom: 8px;

  animation: ${comeUpAnimation} 0.8s ease-out forwards;
  opacity: 0; // Start invisible
`;

const SubTitle = () => (
  <SubTitleContainer>
    Join 500k+ users on the best Uncensored AI Community. Browse and generate
    images and chat with the unfiltered AI bots on UncensoredAI.io
  </SubTitleContainer>
);

const SubTitleContainer = styled.h2`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: ${PRIMARY_TEXT_COLOR};

  align-self: center;

  ${desktopMediaQuery} {
    font-size: 24px;
  }
`;

export default TitlesSection;
