import styled from "styled-components";
import { desktopMediaQuery } from "../../styleHelpers";

export const SecondaryButton = styled.button`
  border: none;
  font-size: 14px;

  padding: 10px;
  padding-left: 16px;
  padding-right: 16px;

  background-color: transparent;
  margin-top: 10px;

  ${desktopMediaQuery} {
    &:hover {
      margin-bottom: 0;
      text-decoration: underline;
    }
  }
`;
