import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useChatContext } from '../../context/ChatContextProvider';
import { waitSeconds } from '../../components/helpers';
import { ChatMessage, ChatState } from '../../types';
import useApi from './useApi';
import useHandleDeductCredits from '../images/useHandleDeductCredits';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';

const HOW_MANY_MESSAGES_TO_KEEP_IN_MEMORY = 20;
const SECONDS_TO_WAIT = 2;

const transformMessages = (newMessages: ChatMessage[]): ChatMessage[] => {
  const filteredMessages = newMessages.slice(
    -HOW_MANY_MESSAGES_TO_KEEP_IN_MEMORY,
  );

  return filteredMessages;
};

const useSendMessage = () => {
  const [{ token }] = useCookies(['token']);
  const { activeChatId, chats, userName } = useChatContext();
  const { loggedInUser, setLoggedInUser } = useLoggedInUserContext();
  const { apiCall } = useApi('sendMessage', 'POST');
  const handleDeductCredits = useHandleDeductCredits();

  const activeChat: ChatState | undefined = chats.find(
    (chat) => chat.id === activeChatId,
  );

  const sendMessage = async (
    newMessages: ChatMessage[],
  ): Promise<ChatMessage | null> => {
    if (!activeChat) {
      toast.error('No active chat selected.');
      return null;
    }

    const DONT_USE_CREDITS = true;
    // TODO clean this up and handle non logged in user case
    // handle deducting credits
    if (!loggedInUser) {
      console.log('todo');
    } else if (DONT_USE_CREDITS) {
      console.log('Not deducting credits');
    } else {
      // deduct 1 credit every second message
      // @ts-ignore it cant be undefined here...
      const isEvenMessage = (loggedInUser.messageCount || 0) % 2 === 1;
      // @ts-ignore it cant be undefined here...
      console.log(loggedInUser.messageCount);
      // @ts-ignore it cant be undefined here...
      setLoggedInUser((prevUser) => ({
        ...prevUser,
        // @ts-ignore
        messageCount: (prevUser.messageCount || 0) + 1,
      }));
      if (isEvenMessage) {
        console.log('Not deducting credits');
      } else {
        handleDeductCredits(1);
      }
    }

    const { botName, chatScenario } = activeChat;

    const messages = transformMessages(newMessages);

    const body = {
      messages,
      botName,
      userName,
      chatScenario,
      token,
    };

    await waitSeconds(SECONDS_TO_WAIT);
    let response = await apiCall(body);
    let receivedMessage = response?.data;

    if (
      response.status !== 200 ||
      !receivedMessage ||
      receivedMessage?.content === ''
    ) {
      await waitSeconds(SECONDS_TO_WAIT);
      response = await apiCall(body);
      receivedMessage = response?.data;

      if (response.status !== 200 || !receivedMessage) {
        toast.error('Something went wrong 😭');
        return null;
      }
    }

    return receivedMessage;
  };

  return sendMessage;
};

export default useSendMessage;
