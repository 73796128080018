import { FaPaperPlane } from 'react-icons/fa';
import styled from 'styled-components';
import {
  EVEN_LIGHTER_GREY_COLOR,
  LIGHT_GREY_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from '../../constants';
import { desktopMediaQuery, getIsOnDesktop } from '../../styleHelpers';
/*
import ExtraOptionsIconAndMenu from './ExtraOptionsIconAndMenu';
<ExtraOptionsIconAndMenu handleFocusOnInput={handleFocusOnInput} />
*/
import { useRef, useEffect } from 'react';
import { useChatContext } from '../../context/ChatContextProvider';
import { waitSeconds } from '../../components/helpers';

const MAX_MESSAGE_LENGTH = 200;

interface ChatInputProps {
  chatInputValue: string;
  setChatInputValue: (message: string) => void;
  handleSendMessage: () => void;
  placeholder: string;
  disableInput?: boolean;
}

const ChatInput = ({
  chatInputValue,
  setChatInputValue,
  handleSendMessage,
  placeholder,
}: ChatInputProps) => {
  const { isFocusedOnKeyboard, setIsFocusedOnKeyboard, botIsTyping } =
    useChatContext();
  const emphasizeInput = false; //placeholder === 'Write only your name...';

  const isOnDesktop = getIsOnDesktop();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [chatInputValue]);

  const onFocus = async () => {
    if (isOnDesktop) return;
    await waitSeconds(0.2);
    setIsFocusedOnKeyboard(true);
  };

  const onBlur = async () => {
    await waitSeconds(0.3);
    setIsFocusedOnKeyboard(false);
  };

  const onSendMessage = async () => {
    if (botIsTyping) return;

    handleSendMessage();
    await waitSeconds(0.2);

    if (isOnDesktop) {
      textareaRef.current?.focus();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > MAX_MESSAGE_LENGTH) {
      e.target.value = e.target.value.slice(0, MAX_MESSAGE_LENGTH);
    }
    setChatInputValue(e.target.value);
  };

  const isSendDisabled = botIsTyping || chatInputValue.trim() === '';

  return (
    <InputContainer
      emphasizeInput={emphasizeInput}
      isFocusedOnKeyboard={isFocusedOnKeyboard}
    >
      <StyledTextarea
        ref={textareaRef}
        emphasizeInput={emphasizeInput}
        placeholder={placeholder}
        value={chatInputValue}
        onChange={handleOnChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSendMessage();
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={MAX_MESSAGE_LENGTH}
        autoComplete="off"
        rows={1}
      />
      <ContainerForSendingButton>
        <PaperPlaneIcon onClick={onSendMessage} disabled={isSendDisabled} />
      </ContainerForSendingButton>
    </InputContainer>
  );
};

export default ChatInput;

/*
<IsDoingActionStar isDoingAction={isDoingAction} />
const IsDoingActionStar = ({ isDoingAction }: { isDoingAction: boolean }) => {
  if (isDoingAction) {
    return (
      <div
        style={{
          color: SECONDARY_COLOR,
          fontSize: '20px',
          marginRight: '8px',
          marginLeft: '8px',
        }}
      >
        *
      </div>
    );
  }
  return null;
};
*/

const InputContainer = styled.div<{
  emphasizeInput: boolean;
  isFocusedOnKeyboard: boolean;
}>`
  position: sticky;
  bottom: 0;

  display: flex;
  align-items: center;
  width: 97%;

  border: 1.5px solid
    ${(props) =>
      props.emphasizeInput ? EVEN_LIGHTER_GREY_COLOR : LIGHT_GREY_COLOR};

  border-radius: 8px;
  margin-left: 6px;

  //margin-bottom: ${(props) => (props.isFocusedOnKeyboard ? '-8px' : '16px')};
  margin-bottom: 16px;

  ${desktopMediaQuery} {
    margin-bottom: 0;
  }
`;
const StyledTextarea = styled.textarea<{
  emphasizeInput: boolean;
  rows: number;
}>`
  width: 100%;
  border: none;
  resize: none;
  overflow: hidden;
  padding: 2px;
  padding-bottom: 0px;
  padding-top: 10px;
  margin-right: 8px;
  margin-left: 12px;
  font-size: 16px;
  font-family: inherit;
  line-height: 1.5;
  min-height: ${props => props.rows * 24}px;
  
  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${(props) => props.emphasizeInput && EVEN_LIGHTER_GREY_COLOR};
    font-weight: ${(props) => (props.emphasizeInput ? 'bold' : 'normal')};
  }

  ${desktopMediaQuery} {
    font-size: 16px;
  }
`;

const ContainerForSendingButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;

const PaperPlaneIcon = styled(FaPaperPlane)<{ disabled: boolean }>`
  transition: color 0.2s;
  color: ${(props) =>
    props.disabled ? SECONDARY_TEXT_COLOR : PRIMARY_TEXT_COLOR};
  font-size: 22px;
  cursor: pointer;
`;
