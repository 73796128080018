import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../constants';
import CommonDropdown from '../../components/common/CommonDropdown';
import { getEngineNamesByType } from '../../utils/engineHelpers';
import userSettingsInStorage from '../../localStorage/userSettingsInStorage';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { FaSliders } from 'react-icons/fa6';

interface FilterButtonProps {
  isFilterSelected?: boolean;
}

const ENGINE_CATEGORIES = ['All', 'Realistic', 'Anime', 'Digital'];

const FilterButton: React.FC<FilterButtonProps> = ({ isFilterSelected }) => {
  const {
    selectedCategory,
    setSelectedCategory,
    selectedEngine,
    setSelectedEngine,
  } = useImagesContext();
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);

  const handleSelection = (option: string) => {
    if (ENGINE_CATEGORIES.includes(option)) {
      setSelectedCategory(option);
      setSelectedEngine('');
    } else {
      setSelectedEngine(option);
      setSelectedCategory('');
    }
  };

  useEffect(() => {
    const realisticEngines = getEngineNamesByType('realistic');
    const animeEngines = getEngineNamesByType('anime');
    const digitalEngines = getEngineNamesByType('digital');

    setOptions([
      ...ENGINE_CATEGORIES,
      ...realisticEngines,
      ...animeEngines,
      ...digitalEngines,
    ]);

    if (selectedCategory === null) return;

    const lastUsedFilter = userSettingsInStorage().lastUsedFilterInFeed.get();
    if (!lastUsedFilter) return;
    if (selectedEngine) return;

    handleSelection(lastUsedFilter);
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    handleSelection(option);
    userSettingsInStorage().lastUsedFilterInFeed.set(option);
    setIsOpen(false);
  };

  const getSelectedOption = () => {
    return selectedCategory || selectedEngine || 'All';
  };

  const renderOption = (option: string) => {
    if (option === 'All') return '🚫 No filter';

    const optionToUse = option === 'Anime' ? 'Hentai' : option;
    if (ENGINE_CATEGORIES.includes(option)) {
      return `All ${optionToUse} engines`;
    }
    return optionToUse;
  };

  return (
    <Container>
      <IconButton onClick={handleToggle} isFilterSelected={isFilterSelected}>
        <FaSliders />
      </IconButton>
      {isOpen && (
        <CommonDropdown
          options={options}
          optionsToRender={options.map(renderOption)}
          selectedOption={getSelectedOption()}
          onOptionClick={handleOptionClick}
          customToggle={handleToggle}
          customIsOpen={isOpen}
          showMenuOnLeft
        />
      )}
    </Container>
  );
};

export default FilterButton;

const Container = styled.div`
  position: relative;

  margin-top: 6px;
  margin-right: -8px;
  margin-left: -2px;
`;

const IconButton = styled.button<{ isFilterSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) =>
    props.isFilterSelected ? PRIMARY_COLOR : PRIMARY_TEXT_COLOR};
  font-size: 20px;
  padding: 4px;
  padding-top: 10px;
  padding-right: 6px;
`;
