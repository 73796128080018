import styled from "styled-components";
import { BORDER_COLOR, PRIMARY_COLOR } from "../../../constants";
import { StyledButton } from "../../common/StyledButton";
import { FolderInterface } from "../../../types";
import { desktopMediaQuery } from "../../../styleHelpers";

const FolderButton = styled(StyledButton)`
  border-color: ${BORDER_COLOR};
  height: 40px;
  width: 275px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: none;

  ${desktopMediaQuery} {
    &:hover {
      border-width: 1px;
      margin-bottom: 0;
      border-color: ${PRIMARY_COLOR};
    }
  }
`;

interface I {
  folder: FolderInterface;
  handleSaveToFolder: () => void;
}

const FolderListItem = ({ folder, handleSaveToFolder }: I) => {
  return (
    <FolderButton onClick={handleSaveToFolder}>{folder.name}</FolderButton>
  );
};

export default FolderListItem;
