import styled from 'styled-components';
import InputSectionInQuickGenerator from './InputSectionInQuickGenerator';
import { PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from '../../constants';
import { desktopMediaQuery, getIsOnDesktop } from '../../styleHelpers';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import GeneratedImagesContainer from '../AdvancedModeTab/GeneratedImagesContainer';
import { ADVANCED_PATH, MAGIC_PATH } from '../../pathNames';
import TitleSectionInHomeGenerator from './TitleSectionInHomeGenerator';
import { FaArrowRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { trackFirstTimeUserClicksOpenTagGen } from '../../utils/analytics';
import FirstTimeUserStorage from '../../localStorage/firstTimeUserStorage';
import { useEffect, useState } from 'react';
import ExampleTagsPromptBox from '../AdvancedModeTab/ExampleTagsPromptBox';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import ButtonsBelowGeneratedImagesInHome from './ButtonsBelowGeneratedImagesInHome';

const HomePageGenerator = () => {
  const { images, loading, setIsSeedFrozen } = useGeneralContext();
  const { userPrompt, setUserPrompt, hasModifiedPrompt, setHasModifiedPrompt } = useImageGenerationSettingsContext();
  const { loggedInUser } = useLoggedInUserContext();

  const hasGeneratedImages = images.length > 0;

  const isOnDesktop = getIsOnDesktop();

  const [isFirstTimeUser, setIsFirstTimeUser] = useState<boolean | null>(null);
  useEffect(() => {
    if (loggedInUser) {
      setIsFirstTimeUser(false);
      return;
    }
    const firstTimeUserStorage = new FirstTimeUserStorage();
    const hasVisitedBefore = firstTimeUserStorage.hasChecked();
    setIsFirstTimeUser(!hasVisitedBefore);
  }, [loggedInUser]);

  const shouldShowOpenTagGeneratorLink = isFirstTimeUser && !hasGeneratedImages;

  const shouldShowOpenAdvancedGeneratorLink =
    !hasGeneratedImages && isOnDesktop && !isFirstTimeUser;

  const exampleTags = [
    'Hot blonde',
    'Cute brunette',
    'wearing blue dress',
    'wearing red lingerie',
    'full body portrait',
    'big boobs',
    'Swedish 25 yo',
    'nude',
    'sexy redhead',
    'in a dorm room',
    'in a hotel room',
    'topless',
    'messy blonde hair',
    'sitting in a gaming chair',
    'very short brown pixie hair',
    'hour glass figure',
  ];

  const [tags, setTags] = useState<string[]>(exampleTags);

  // Update freezeSeed whenever prompt changes
  useEffect(() => {
    setIsSeedFrozen(hasModifiedPrompt);
  }, [hasModifiedPrompt, setIsSeedFrozen]);

  const handleClickedTag = (tag: string) => {
    setTags(tags.filter((t) => t !== tag));
    setHasModifiedPrompt(true);

    if (userPrompt === '') {
      setUserPrompt(tag);
      return;
    }
    setUserPrompt(userPrompt + ', ' + tag);
  };

  const showTags = !hasGeneratedImages && isFirstTimeUser;
  return (
    <Container hasGeneratedImages={hasGeneratedImages}>
      {hasGeneratedImages ? (
        <div>
          <GeneratedImagesContainer hideRandomTip smallerForHomepage />
          {!loading && <ButtonsBelowGeneratedImagesInHome />}
        </div>
      ) : (
        <TitleSectionInHomeGenerator />
      )}

      <InputSectionInQuickGenerator />

      {showTags && (
        <TagsContainer>
          <ExampleTagsPromptBox
            handleClickedTag={handleClickedTag}
          tags={tags}
          wider
          />
        </TagsContainer>
      )}

      {shouldShowOpenTagGeneratorLink && (
        <BottomAdvGenLink
          to={MAGIC_PATH}
          smallerPadding={hasGeneratedImages}
          onClick={trackFirstTimeUserClicksOpenTagGen}
        >
          Generate using Tag Mode
          <FaArrowRight color={PRIMARY_TEXT_COLOR} size={18} />
        </BottomAdvGenLink>
      )}

      {shouldShowOpenAdvancedGeneratorLink && (
        <BottomAdvGenLink to={ADVANCED_PATH}>
          Open Advanced Generator
          <FaArrowRight color={PRIMARY_TEXT_COLOR} size={18} />
        </BottomAdvGenLink>
      )}
    </Container>
  );
};

const Container = styled.div<{ hasGeneratedImages: boolean }>`
  display: flex;
  flex-direction: column;

  //if hasnt generated images then height 60vh, otherwise 100%;
  height: ${({ hasGeneratedImages }) => (hasGeneratedImages ? '100%' : '60vh')};
  margin-bottom: ${({ hasGeneratedImages }) => (hasGeneratedImages ? '40px' : '0')};
  justify-content: center;
  align-items: center;

  width: 94vw;
  ${desktopMediaQuery} {
    max-height: 1000px;
    margin-left: 5vw;
    width: 50vw;
  }
`;

const TagsContainer = styled.div`
  margin-top: 16px;
`;

const BottomAdvGenLink = styled(Link)<{
  smallerPadding?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 4px;

  color: ${SECONDARY_TEXT_COLOR};
  font-size: 16px;

  text-decoration: none;

  margin-top: -4px;
  margin-bottom: 16px;

  &:hover {
    color: ${PRIMARY_TEXT_COLOR};
  }
  padding-top: ${({ smallerPadding }) => (smallerPadding ? '8' : '36')}px;
`;

export default HomePageGenerator;
