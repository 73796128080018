import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import useGetPostedImages from '../../hooks/feed/useGetPostedImages';
import { FeedChannel, SharedImage } from '../../types';
import { useNavigate } from 'react-router-dom';
import { FEED_PATH } from '../../pathNames';
import LoadingAnimation from '../../components/common/LoadingStuff/LoadingAnimation';
import {
  filterImagesByAlreadySeen,
  filterImagesByButton,
} from '../FeedPage/feedPageHelpers';
import CategoryFilterButtons from './CategoryFilterButtons';
import BasicContainerWithFadeIn from '../../components/common/BasicContainerWithFadeIn';
import HomePageImageFeedContent from './HomePageImageFeedContent';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { desktopMediaQuery } from '../../styleHelpers';
import { FaRegHandPointDown } from 'react-icons/fa';
import { SECONDARY_TEXT_COLOR } from '../../constants';

const CATEGORIES = ['All', 'Realistic', 'Anime', 'Digital'];

const HomePageImageFeed: React.FC = () => {
  const [allImages, setAllImages] = useState<SharedImage[]>([]);
  const [imagesToShowIfBrowsingMore, setImagesToShowIfBrowsingMore] = useState<
    SharedImage[]
  >([]);
  const [imagesToDisplay, setImagesToDisplay] = useState<SharedImage[]>([]);
  const getPostedImages = useGetPostedImages();
  const navigate = useNavigate();

  const { loggedInUser } = useLoggedInUserContext();

  const { selectedCategory, setSelectedCategory } = useImagesContext();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      const sortToUse = loggedInUser ? 'hot' : 'top';
      setLoading(true);
      const sort = sortToUse;
      const channelName = 'all';
      const channelList: FeedChannel[] = [];
      const images = await getPostedImages({
        sort,
        channelName,
        channelList,
      });
      setAllImages(images);
      setLoading(false);
    };
    fetchImages();
  }, [loggedInUser]);

  useEffect(() => {
    setLoading(true);
    if (allImages.length === 0) return;
    const imagesFilteredByButton = filterImagesByButton(
      allImages,
      selectedCategory,
      undefined,
    );

    const shouldFilterSeen = loggedInUser ? true : false;
    const imagesFilteredByAlreadySeen = filterImagesByAlreadySeen(
      imagesFilteredByButton,
      shouldFilterSeen,
    );

    setImagesToShowIfBrowsingMore(imagesFilteredByAlreadySeen);

    const onlyTallPortraits = imagesFilteredByAlreadySeen.filter(
      (image) => image.image.height === 768,
    );
    const top10Images = onlyTallPortraits.slice(0, 10);
    setImagesToDisplay(top10Images);

    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [selectedCategory, allImages]);

  const handleNavToFeedPage = () => {
    navigate(FEED_PATH);
  };

  return (
    <FeedOfImagesContainer>
      <ExploreText>
        Explore below <StyledPointDown />
      </ExploreText>
      <CategoryFilterButtons
        categories={CATEGORIES}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      {loading ? (
        <LoadingAnimation
          loaderToChoose={2}
          loading={true}
          style={{ marginTop: '80px' }}
        />
      ) : (
        <HomePageImageFeedContent
          imagesToShowIfBrowsingMore={imagesToShowIfBrowsingMore}
          imagesToDisplay={imagesToDisplay}
          handleNavToFeedPage={handleNavToFeedPage}
        />
      )}
    </FeedOfImagesContainer>
  );
};

const FeedOfImagesContainer = styled(BasicContainerWithFadeIn)`
  width: 94vw;
  margin-top: 8px;

  ${desktopMediaQuery} {
    margin-left: 5vw;
  }
`;

// bigish title that tells users they can explore content below
const ExploreText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0);
  }
`;
const StyledPointDown = styled(FaRegHandPointDown)`
  color: ${SECONDARY_TEXT_COLOR};
  font-size: 22px;
  animation: ${bounce} 0.8s infinite;
  animation-iteration-count: 10;
`;

export default HomePageImageFeed;
