import styled from 'styled-components';
import TransitioningModal from '../../../components/Modals/TransitioningModal';
import { BACKGROUND_COLOR } from '../../../constants';
import TextFilter from '../../../components/common/TextFilter';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FEED_PATH } from '../../../pathNames';
import ChannelCard from './ChannelCard';
import { FeedChannel } from '../../../types';
import { desktopMediaQuery, getIsOnDesktop } from '../../../styleHelpers';
import { AiOutlineClose } from 'react-icons/ai';
import { useImagesContext } from '../../../context/ImagesContextProvider';

interface I {
  channels: FeedChannel[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const ChannelsMenuModal = ({ channels, showModal, setShowModal }: I) => {
  const navigate = useNavigate();
  const { setSelectedChannel } = useImagesContext();

  const [channelFilter, setChannelFilter] = useState('');

  const channelsToRender = channels.filter((channel) =>
    channel.name.toLowerCase().includes(channelFilter.toLowerCase()),
  );

  const handleSelectChannel = (channel: string) => {
    setSelectedChannel(channel);
    navigate(FEED_PATH);
    setShowModal(false);
  };

  const isOnDesktop = getIsOnDesktop();

  const contentToRender = () => {
    if (channelFilter && channelsToRender.length === 0) {
      return <Caption>No channels found, try another search</Caption>;
    }

    return (
      <ChannelList>
        {channelsToRender.map((channel) => (
          <ChannelCard
            key={channel._id}
            channel={channel}
            handleSelectChannel={handleSelectChannel}
          />
        ))}
      </ChannelList>
    );
  };

  return (
    <TransitioningModal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 150,
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: '16px',
          width: '100%',
          maxWidth: isOnDesktop ? '800px' : '500px',
          minHeight: '300px',
          maxHeight: isOnDesktop ? '1000px' : '90vh',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={() => setShowModal(false)}
    >
      <CloseButton onClick={() => setShowModal(false)} />
      <ContainerForChannelList>
        <ChannelsText># Channels</ChannelsText>
        <TextFilter
          filter={channelFilter}
          handleSetFilter={setChannelFilter}
          placeholderText="Search for a channel"
          autoUpdateAfterNoInput
        />

        {contentToRender()}
      </ContainerForChannelList>
    </TransitioningModal>
  );
};
const Caption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled(AiOutlineClose)`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  cursor: pointer;
`;

const ContainerForChannelList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ChannelsText = styled.div`
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 12px;
  margin-left: 8px;
`;

const ChannelList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  justify-content: center;
  overflow-y: auto;
  max-height: 70vh;
  width: 100%;
  gap: 1px;

  ${desktopMediaQuery} {
    max-height: 75vh;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default ChannelsMenuModal;
