import { useEffect, useState } from 'react';
import initializeFingerprint from '../../utils/initializeFingerprint';
import { getBaseUrlForFetching } from '../../App';
import { headers } from '../misc/helpers';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import FirstTimeUserStorage from '../../localStorage/firstTimeUserStorage';

/**
 * This checks using fingerprint if user is very first time
 * If they are, give them one time 3 credits (it's a special token that's handled by backend)
 * Makes a call checkFingerPrintForFirstTimeUser (param fingerprint) which returns true/false and a token if they are (i.e. true)
 * Returns a state object isFirstTimeUser
 **/
const useHandleIfUserIsVeryFirstTimeUser = () => {
  const [fingerprint, setFingerprint] = useState<string>('');
  const initFingerprint = async () => {
    const f = await initializeFingerprint();
    if (!f) return;
    setFingerprint(f);
  };

  const { setIsFirstTimeUser, setFirstTimeUserToken } = useGeneralContext();

  const makeCallToCheckIfFirstTimeUser = async () => {
    if (fingerprint === '') return;

    const firstTimeUserStorage = new FirstTimeUserStorage();

    // Check if isFirstTimeUser is stored in localStorage
    const hasCheckedForFirstTimeUser = firstTimeUserStorage.hasChecked();

    if (hasCheckedForFirstTimeUser) {
      setIsFirstTimeUser(false);
      return;
    }

    const functionName = 'checkFingerPrintForFirstTimeUser';
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(fetchUrl, {
      method: 'POST',
      body: JSON.stringify({ fingerprint }),
      headers,
    });
    const data = await response.json();

    firstTimeUserStorage.setChecked();

    if (data.isFirstTimeUser) {
      setIsFirstTimeUser(true);
      setFirstTimeUserToken(data.token);
      return;
    }

    setIsFirstTimeUser(false);
  };

  useEffect(() => {
    initFingerprint();
    makeCallToCheckIfFirstTimeUser();
  }, [fingerprint]);
};

export default useHandleIfUserIsVeryFirstTimeUser;
