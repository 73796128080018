import React, { useState } from 'react';
import { getAmountOfCredits } from './helpers';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import styled from 'styled-components';
import BuyCreditsModal from '../../Modals/TopMenuModals/BuyCreditsModal';
import { OptionContainer } from './TopBarMenu';
import { BiCoin } from 'react-icons/bi';
import { GOLD_COLOR } from '../../../constants';

const CreditsInPopup: React.FC = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const amountOfCredits = getAmountOfCredits(loggedInUser);
  const [showModal, setShowModal] = useState(false);

  const handleBuyCredits = () => {
    setShowModal(true);
  };

  // this shouldn't happen; it's a string only if Gold, and if Gold this shouldn't be shown in the first place
  if (typeof amountOfCredits === 'string') return null;

  return (
    <>
      <OptionContainer onClick={handleBuyCredits}>
        <Container>
          <BuyCreditsButton>
            <BiCoin size={18} color={GOLD_COLOR} /> Buy Credits
          </BuyCreditsButton>
        </Container>
      </OptionContainer>
      <BuyCreditsModal
        showModal={showModal}
        setShowModal={setShowModal}
        amountOfCredits={amountOfCredits}
      />
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  font-weight: 400;
`;

const BuyCreditsButton = styled.button`
  display: flex;
  align-items: center;

  background-color: transparent;
  border: none;

  font-size: 14px;

  gap: 8px;
`;

export default CreditsInPopup;
