import styled from 'styled-components';
import { BACKGROUND_COLOR, GOLD_COLOR } from '../../../constants';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import TransitioningModal from '../TransitioningModal';
import { useImageGenerationSettingsContext } from '../../../context/ImageGenerationSettingsProvider';
import { BiCoin } from 'react-icons/bi';
import { SpeedModeType } from '../../../types';
import { getEngineUsingModelId } from '../../../utils/engineHelpers';

interface HomePageSettingsModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const HomePageSettingsModal = ({
  show,
  setShow,
}: HomePageSettingsModalProps) => {
  const { speedMode, setSpeedMode, engine, setEngine } =
    useImageGenerationSettingsContext();

  const handleClose = () => setShow(false);

  const handleModeChange = (mode: SpeedModeType, modelId: string) => {
    const engine = getEngineUsingModelId(modelId);
    setSpeedMode(mode);
    setEngine(engine);
    handleClose();
  };

  return (
    <Modal
      isOpen={show}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          position: 'relative',
          backgroundColor: BACKGROUND_COLOR,
          padding: '16px',
          width: '90vw',
          maxWidth: '400px',
          height: 'auto',
          margin: 'auto',
          marginTop: '20vh',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ModalTitle>Settings</ModalTitle>
      <CloseButtonInModal onClick={handleClose} />

      <SettingsSection>
        <SectionLabel>Generation Mode</SectionLabel>
        <RadioGroup>
          <RadioOption>
            <RadioInput
              type="radio"
              name="generationMode"
              checked={speedMode === 'adTurbo'}
              onChange={() => handleModeChange('adTurbo', 'super-mini')}
            />
            <RadioLabelGroup>
              <RadioLabel>Super Mini with ads (Free)</RadioLabel>
              <RadioDescription>
                Basic image generation with ads. Good for simple designs.
              </RadioDescription>
            </RadioLabelGroup>
          </RadioOption>

          <RadioOption>
            <RadioInput
              type="radio"
              name="generationMode"
              checked={
                speedMode === 'turbo' && engine.modelId === 'superv1mini'
              }
              onChange={() => handleModeChange('turbo', 'superv1mini')}
            />
            <RadioLabelGroup>
              <RadioLabel>
                Super Mini, costs 3x<BiCoin color={GOLD_COLOR} />
              </RadioLabel>
              <RadioDescription>
                Ad-free basic image generation. Good for simple designs.
              </RadioDescription>
            </RadioLabelGroup>
          </RadioOption>

          <RadioOption>
            <RadioInput
              type="radio"
              name="generationMode"
              checked={
                speedMode === 'turbo' && engine.modelId === 'superv1'
              }
              onChange={() => handleModeChange('turbo', 'superv1')}
            />
            <RadioLabelGroup>
              <RadioLabel>
                Full Super, costs 6x<BiCoin color={GOLD_COLOR} />
              </RadioLabel>
              <RadioDescription>
                Higher resolution, better for complex designs and detailed
                images.
              </RadioDescription>
            </RadioLabelGroup>
          </RadioOption>
        </RadioGroup>
      </SettingsSection>
    </Modal>
  );
};

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  z-index: 3;
`;

const ModalTitle = styled.h2`
  margin: 0 0 24px 0;
  text-align: center;
`;

const SettingsSection = styled.div`
  margin: 16px 0;
`;

const SectionLabel = styled.div`
  font-weight: 500;
  margin-bottom: 12px;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RadioOption = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RadioInput = styled.input`
  margin-right: 8px;
  cursor: pointer;
`;

const RadioLabel = styled.span`
  cursor: pointer;
`;

const RadioLabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const RadioDescription = styled.span`
  font-size: 0.8rem;
  color: #888;
  cursor: pointer;
`;

export default HomePageSettingsModal;
