import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR_DIMMED,
  PRIMARY_TEXT_COLOR,
} from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';

export const StyledButton = styled.button<{ inLandingPage?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 4px;
  padding: 10px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  border: 1px solid ${PRIMARY_COLOR};
  color: ${PRIMARY_COLOR};
  margin-top: 10px;
  color: ${PRIMARY_TEXT_COLOR};
  background-color: ${BACKGROUND_COLOR};
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      transform: translateY(-2px);
      transition: transform 0.2s;
    }
  }

  box-shadow: 4px 4px 4px ${PRIMARY_COLOR_DIMMED};
`;

export const SmallStyledButton = styled(StyledButton)`
  padding: 2px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 14px;
  margin-top: 0;
  box-shadow: none;
`;

export const SmallSelectableStyledButton = styled(SmallStyledButton)<{
  isSelected?: boolean;
}>`
  padding-left: 8px;
  padding-right: 8px;
  font-size: 16px;
  border: 1px solid
    ${(props) => (props.isSelected ? PRIMARY_COLOR : BORDER_COLOR)};
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
  transition: border 0.1s;
  transition: font-weight 0.1s;

  box-shadow: none;
`;
