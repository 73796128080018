import { ChatState } from '../types';

const MAX_CHATS = 40

function getLastActivityTime(chat: ChatState): number {
  if (!chat.messages || chat.messages.length === 0) return 0
  return Math.max(...chat.messages.map(msg => new Date(msg.timestamp).getTime()))
}

class ChatSettingsStorage {
  private storageKey: string;

  private usernameKey: string;

  private chatsKey: string;
  private activeChatIdKey: string;

  constructor() {
    this.storageKey = 'userSettings';
    this.usernameKey = 'userName';

    this.chatsKey = 'chats';
    this.activeChatIdKey = 'activeChatId';
  }

  // Existing methods for user settings
  getUserName(): string | null {
    const fullKey = `${this.storageKey}.${this.usernameKey}`;
    const userName = localStorage.getItem(fullKey);
    return userName ? JSON.parse(userName) : null;
  }

  setUserName(userName: string): void {
    const fullKey = `${this.storageKey}.${this.usernameKey}`;
    localStorage.setItem(fullKey, JSON.stringify(userName));
  }

  getSettings(): {
    userName: string | null;
  } {
    return {
      userName: this.getUserName(),
    };
  }

  // New methods for managing chats

  /**
   * Retrieves all saved chats from localStorage.
   * @returns An array of ChatState objects.
   */
  getChats(): ChatState[] {
    const fullKey = `${this.storageKey}.${this.chatsKey}`;
    const chats = localStorage.getItem(fullKey);
    return chats ? JSON.parse(chats) : [];
  }

  /**
   * Saves a new chat to localStorage.
   * @param newChat The ChatState object to be added.
   */
  saveChat(newChat: ChatState): void {
    let chats = this.getChats()
    
    if (chats.length >= MAX_CHATS) {
      // Sort by last activity time, inactive (0) chats will be first
      chats.sort((a, b) => getLastActivityTime(a) - getLastActivityTime(b))
      // Remove oldest inactive chat first, if none inactive remove oldest by activity
      chats = chats.slice(1)
    }
    
    chats.unshift(newChat)
    const fullKey = `${this.storageKey}.${this.chatsKey}`
    localStorage.setItem(fullKey, JSON.stringify(chats))
  }

  /**
   * Updates an existing chat in localStorage.
   * @param chatId The ID of the chat to update.
   * @param updatedChat Partial ChatState object with updated fields.
   */
  updateChat(chatId: string, updatedChat: Partial<ChatState>): void {
    const chats = this.getChats();
    const updatedChats = chats.map((chat) =>
      chat.id === chatId ? { ...chat, ...updatedChat } : chat,
    );
    const fullKey = `${this.storageKey}.${this.chatsKey}`;
    localStorage.setItem(fullKey, JSON.stringify(updatedChats));
  }

  /**
   * Deletes a chat from localStorage.
   * @param chatId The ID of the chat to delete.
   */
  deleteChat(chatId: string): void {
    const chats = this.getChats();
    const filteredChats = chats.filter((chat) => chat.id !== chatId);
    const fullKey = `${this.storageKey}.${this.chatsKey}`;
    localStorage.setItem(fullKey, JSON.stringify(filteredChats));
  }

  /**
   * Retrieves the currently active chat ID from localStorage.
   * @returns The active chat ID as a string.
   */
  getActiveChatId(): string {
    const fullKey = `${this.storageKey}.${this.activeChatIdKey}`;
    const activeChatId = localStorage.getItem(fullKey);
    return activeChatId ? JSON.parse(activeChatId) : '';
  }

  /**
   * Sets the active chat ID in localStorage.
   * @param chatId The ID of the chat to set as active.
   */
  setActiveChatId(chatId: string): void {
    const fullKey = `${this.storageKey}.${this.activeChatIdKey}`;
    localStorage.setItem(fullKey, JSON.stringify(chatId));
  }
}

export default ChatSettingsStorage;
