import React from 'react';
import styled from 'styled-components';
import { IoMdCheckmark } from 'react-icons/io';
import { BORDER_COLOR, GOLD_COLOR, PRIMARY_COLOR, SECONDARY_TEXT_COLOR } from '../../../constants';

interface CardProps {
  price: number;
  credits: number;
  tag?: string;
  benefitListItems: string[];
  onBuy: () => void;
}

const PaymentCard: React.FC<CardProps> = ({
  price,
  credits,
  tag,
  benefitListItems,
  onBuy,
}) => (
  <CardContainer>
    {tag && <Tag>{tag}</Tag>}
    <Price>${price.toFixed(2)}</Price>
    <Credits>{credits} credits</Credits>
    <BenefitsList>
      {benefitListItems.map((benefit, index) => (
        <BenefitItem key={index}>
          <IoMdCheckmark color="#007bff" />
          <span style={{ marginLeft: '8px' }}>{benefit}</span>
        </BenefitItem>
      ))}
    </BenefitsList>
    <BuyButton onClick={onBuy}>Buy now {price.toFixed(2)}$</BuyButton>
  </CardContainer>
);

export default PaymentCard;

const CardContainer = styled.div`
  border: 1px solid ${BORDER_COLOR};
  border-radius: 10px;
  padding: 16px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  text-align: center;
`;

const Tag = styled.div`
  background-color: ${GOLD_COLOR};
  color: #4e4e4e;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  position: absolute;
  top: -16px;
  left: 8px;
`;

const Price = styled.h2`
  font-size: 32px;
  margin: 0;
  margin-top: 16px;
`;

const Credits = styled.p`
  font-size: 18px;
  color: ${SECONDARY_TEXT_COLOR};
  margin-top: 8px;
`;

const BenefitsList = styled.ul`
  list-style: none;
  padding: 0;
`;

const BenefitItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const BuyButton = styled.button`
  background-color: ${PRIMARY_COLOR};
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;
