import { FaHeart, FaRegHeart } from 'react-icons/fa6';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import SaveToast from '../common/SaveToast';
import SaveToFolderModal from '../Modals/SaveToFolderModal/SaveToFolderModal';
import { useSaveImageLogic } from '../../hooks/images/useSaveImageLogic';
import { TIME_TO_CLOSE_TOAST } from '../../MainApp';
import { useGeneralContext } from '../../context/GeneralContextProvider';

interface SaveImageMenuItemProps {
  onSave: () => void;
}

const SaveImageMenuItem = ({ onSave }: SaveImageMenuItemProps) => {
  const { activeImage } = useGeneralContext();

  const triggerToast = () => {
    const handleSaveToFolder = () => {
      setShowSaveToFolderModal(true);
    };

    toast(
      <SaveToast
        imageSrc={activeImage.imageUrl}
        onSaveToFolder={handleSaveToFolder}
      />,
      {
        className: 'SaveToast',
        autoClose: TIME_TO_CLOSE_TOAST,
        position: 'bottom-center',
      },
    );
  };

  const {
    isSaved,
    showSaveToFolderModal,
    setShowSaveToFolderModal,
    handleSaveImage: handleSave,
  } = useSaveImageLogic({ image: activeImage, triggerToast });

  const handleClick = () => {
    handleSave();
    onSave();
  };

  return (
    <>
      <MenuItemContainer onClick={handleClick}>
        {isSaved ? <FaHeart /> : <FaRegHeart />}
        {isSaved ? 'Unsave the image' : 'Save the image'}
      </MenuItemContainer>
      <SaveToFolderModal
        show={showSaveToFolderModal}
        setShow={setShowSaveToFolderModal}
        image={activeImage}
      />
    </>
  );
};

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 12px;
  cursor: pointer;
  gap: 16px;
  width: 100%;
  padding: 8px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
`;

export default SaveImageMenuItem;
