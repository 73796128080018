import styled from 'styled-components';
import { GOLD_COLOR } from '../../../constants';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { GenericSetState } from '../../../types';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import { BiCoin } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import {
  ContainerPaymentModal,
  Modal,
  ModalText,
  paymentModalContentStylesDesktop,
  paymentModalContentStylesMobile,
  paymentModalOverlayStyles,
} from './PaymentCommonComponents';
import { useCookies } from 'react-cookie';
import useHandleGetUser from '../../../hooks/userHandling/useHandleGetUser';
import useCreateRedirectUrlForEmerchantpay from '../../../hooks/useCreateRedirectUrlForEmerchantpay';
import LoadingIndicator from '../../common/LoadingIndicator';
import { desktopMediaQuery, getIsOnDesktop } from '../../../styleHelpers';
import PaymentCard from './PaymentCard';
import useCreateRedirectUrlForCentrobill from '../../../hooks/useCreateRedirectUrlForCentrobill';

interface I {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
  amountOfCredits: number;
}

export const PAYMENTS_DOWN = false;
export const USE_CENTROBILL = true;

type PaymentTypes = 'smallCredits' | 'mediumCredits' | 'largeCredits';

const BuyCreditsModal = ({ showModal, setShowModal, amountOfCredits }: I) => {
  const [loadingRedirect, setLoadingRedirect] = useState(false);
  const { loggedInUser } = useLoggedInUserContext();
  const [cookies] = useCookies(['token']);

  const handleGetUser = useHandleGetUser();

  const handleClose = () => {
    setShowModal(false);
  };

  const createUrlToRedirectForEmerchantpay =
    useCreateRedirectUrlForEmerchantpay();
  const createUrlToRedirectForCentrobill = useCreateRedirectUrlForCentrobill();

  const handleBuyCredits = async (paymentType: PaymentTypes) => {
    if (PAYMENTS_DOWN && loggedInUser?.username !== 'malossi') {
      alert('Payments are down, please try again later');
      return;
    }

    setLoadingRedirect(true);
    try {
      let urlToRedirect;
      if (USE_CENTROBILL) {
        urlToRedirect = await createUrlToRedirectForCentrobill(paymentType);
      } else {
        urlToRedirect = await createUrlToRedirectForEmerchantpay(paymentType);
      }
      
      if (urlToRedirect) window.location.href = urlToRedirect;
    } finally {
      setLoadingRedirect(false);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const disableUpdating = true;
    if (disableUpdating) return;
    if (showModal) {
      const token = cookies.token;
      if (token) {
        intervalId = setInterval(async () => {
          handleGetUser(token);
        }, 3000);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [showModal]);

  const isDesktop = getIsOnDesktop();

  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: paymentModalOverlayStyles,
        content: isDesktop
          ? paymentModalContentStylesDesktop
          : paymentModalContentStylesMobile,
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ContainerPaymentModal>
        <CloseButtonInModal onClick={handleClose}>X</CloseButtonInModal>
        <CurrentCredits amountOfCredits={amountOfCredits} />
        {loadingRedirect ? (
          <ModalText>
            Redirecting to payment gateway... <LoadingIndicator />
          </ModalText>
        ) : (
          <Content handleBuyCredits={handleBuyCredits} />
        )}
      </ContainerPaymentModal>
    </Modal>
  );
};

const CurrentCredits = ({ amountOfCredits }: { amountOfCredits: number }) => (
  <CurrentCreditsContainer>
    <b>Credits:</b>
    <BiCoin size={20} color={GOLD_COLOR} style={{ marginLeft: 8 }} />
    {'x '}
    {amountOfCredits}
  </CurrentCreditsContainer>
);

const CurrentCreditsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 20px;
  margin-bottom: 16px;
  margin-left: 8px;
`;

type ContentProps = {
  handleBuyCredits: (paymentType: PaymentTypes) => void;
};

// 2 messages per 1 credit
// 1 turbo image per 3 credits
// 1 fast image per 1 credit
// 1 image enhancement per 5 credits
// 1 super image per 5 credits
const getBenefitItems = (credits: number) => {
  const messages = Math.floor(credits * 2);
  const turboImages = Math.floor(credits / 3);
  const fastImages = credits;
  const imageEnhancements = Math.floor(credits / 5);
  const superImages = Math.floor(credits / 6);
  return [
    `${messages} AI messages`,
    `${fastImages} Fast images`,
    `${turboImages} Turbo images`,
    `${imageEnhancements} Image Enhancements`,
    `${superImages} Super images`,
  ];
};
const Content = ({ handleBuyCredits }: ContentProps) => {
  return (
    <ContentContainer>
      <PaymentCard
        price={4.9}
        credits={300}
        benefitListItems={getBenefitItems(300)}
        onBuy={() => handleBuyCredits('smallCredits')}
      />
      <PaymentCard
        price={9.9}
        credits={800}
        benefitListItems={getBenefitItems(800)}
        onBuy={() => handleBuyCredits('mediumCredits')}
        tag="Popular, Save 25%"
      />
      <PaymentCard
        price={19.9}
        credits={1800}
        benefitListItems={getBenefitItems(1800)}
        onBuy={() => handleBuyCredits('largeCredits')}
        tag="Best deal, Save 33%"
      />
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  margin-bottom: 16px;
  padding-bottom: 40px;

  ${desktopMediaQuery} {
    width: 80%;
    flex-direction: row;
  }
`;

export default BuyCreditsModal;
