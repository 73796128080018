import styled from 'styled-components';
import { BACKGROUND_COLOR, LIGHT_GREY_COLOR } from '../../../constants';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import TransitioningModal from '../TransitioningModal';
import SingleLineInputField from '../../../pages/ChatPage/SingleLineInputField';
import { useChatContext } from '../../../context/ChatContextProvider';
import { useEffect, useRef } from 'react';
import { StyledButton } from '../../common/StyledButton';
import useStartChat from '../../../hooks/chat/useStartChat';
import { CloneChatButton } from './CloneChatButton';

interface ChatSettingsModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const ChatSettingsModal = ({ show, setShow }: ChatSettingsModalProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { activeChatId, chats, updateChat, userName, setUserName } =
    useChatContext();

  const { startNewChatWithOngoingChat } = useStartChat();

  const activeChat = chats.find((chat) => chat.id === activeChatId);

  const handleClose = () => setShow(false);

  const handleBotNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!activeChat) return;
    updateChat(activeChat.id, {
      ...activeChat,
      botName: e.target.value,
    });
  };

  const adjustHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = '40px';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (show && activeChat?.chatScenario) {
      setTimeout(adjustHeight, 0);
    }
  }, [show, activeChat?.chatScenario]);

  const handleScenarioChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!activeChat) return;
    updateChat(activeChat.id, {
      ...activeChat,
      chatScenario: e.target.value,
    });
  };

  const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const handleStartNewChat = () => {
    if (!activeChat) return;
    startNewChatWithOngoingChat();
    handleClose();
  };

  return (
    <Modal
      isOpen={show}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          position: 'relative',
          backgroundColor: BACKGROUND_COLOR,
          padding: '16px',
          width: '90vw',
          maxWidth: '400px',
          height: 'auto',
          margin: 'auto',
          marginTop: '4vh',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ModalTitle>Chat Settings</ModalTitle>
      <CloseButtonInModal onClick={handleClose} />

      <CloneChatButton onClone={handleStartNewChat} />

      <SettingsSection>
        <SectionLabel>Bot Name</SectionLabel>
        <SingleLineInputField
          value={activeChat?.botName || ''}
          onChange={handleBotNameChange}
          placeholder="Enter bot name"
          hasBorder={true}
        />
      </SettingsSection>

      <SettingsSection>
        <SectionLabel>Chat Instructions / Scenario</SectionLabel>
        <ScenarioTextarea
          value={activeChat?.chatScenario || ''}
          onChange={handleScenarioChange}
          placeholder="Enter chat scenario and bot personality"
          ref={textareaRef}
        />
      </SettingsSection>

      <SettingsSection>
        <SectionLabel>Your Name</SectionLabel>
        <SingleLineInputField
          value={userName || ''}
          onChange={handleUserNameChange}
          placeholder="Enter your name"
          hasBorder={true}
        />
      </SettingsSection>

      <ButtonContainer>
        <StyledButton onClick={handleClose}>OK</StyledButton>
      </ButtonContainer>
    </Modal>
  );
};

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  z-index: 3;
`;

const ModalTitle = styled.h2`
  margin: 0 0 24px 0;
  text-align: center;
`;

const SettingsSection = styled.div`
  margin: 16px 0;
  width: 93%;
`;

const SectionLabel = styled.div`
  font-weight: 500;
  margin-bottom: 12px;
`;

const ScenarioTextarea = styled.textarea`
  width: 105%;
  border: 1px solid ${LIGHT_GREY_COLOR};
  border-radius: 4px;
  resize: none;
  overflow: hidden;
  padding: 8px 12px;
  box-sizing: border-box;
  font-size: 16px;
  min-height: 40px;
  height: auto;
  line-height: 1.5;
  background: none;
  color: inherit;

  &:focus {
    outline: none;
    border-color: ${LIGHT_GREY_COLOR};
  }

  &::placeholder {
    color: ${LIGHT_GREY_COLOR};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 16px;
`;

export default ChatSettingsModal;
