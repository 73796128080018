import { getRecentlyUsedStylesFromLocalStorage } from '../localStorage/stylesStorage';
import { StyleInterface } from '../types';
import {
  getEngineUsingModelId,
  getExampleImageSrc,
  superEngineV1,
  superEngineV1Mini,
} from './engineHelpers';

export const getDefaultStyles = (): StyleInterface[] => {
  const realisticEngine5 = getEngineUsingModelId('realistic_vision_v5.1');
  const realisticEngine4 = getEngineUsingModelId('realistic_vision_v4');
  const realisticPornEngine = getEngineUsingModelId(
    'uber-realistic-porn-merge',
  );
  const hentaiGoldEngine = getEngineUsingModelId('aom_v3');
  const hentai3Engine = getEngineUsingModelId('anything_v5');
  const furryAnimeEngine = getEngineUsingModelId('yiffymix_v3.3');
  const digitalBabesEngine = getEngineUsingModelId('babes_v2');
  return [
    {
      id: '1.31',
      name: 'Super Mini',
      prefixPrompt: '',
      suffixPrompt: 'high quality',
      negativePrompt: '',
      engine: superEngineV1Mini,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/645912e2-943d-4f41-93ff-ed091c2d7cea.jpeg',
    },
    {
      id: '1.3',
      name: 'Super',
      prefixPrompt: '',
      suffixPrompt: 'high quality',
      negativePrompt: '',
      engine: superEngineV1,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/77906d22-2827-4c96-bac8-fefa333b6508.jpeg',
    },
    {
      id: '5',
      name: 'Fluffy hentai',
      prefixPrompt: '(hentai drawing)',
      suffixPrompt:
        '((masterpiece)), (highly detailed:1.3), 8k wallpaper, (trending on Art Station)',
      negativePrompt:
        '((airbrushed)), (eye contact), (worst quality, low quality:1.4), bad finger, watermark, lowres, ((bad anatomy)), ((bad hands)), missing finger, blurry, ((mutated hands and fingers)), (poorly drawn face), ((mutation)), ((deformed face)), (ugly), ((bad proportions)), (double head), monster, logo, cropped, worst quality, close up, normal quality, jpeg, humpbacked, long body, long neck, ((jpeg artifacts)), (deformed penis), ((disembodied penis)), (mutated penis), ((fused body parts:1.2))',
      engine: furryAnimeEngine,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/1697041084571.jpeg',
    },
    {
      id: '1.2',
      name: 'Realistic v2',
      prefixPrompt: '(RAW photo:0.5)',
      suffixPrompt:
        '8k uhd, dslr, soft lighting, high quality, film grain, Fujifilm XT3, hasselblad, gigapixel, detailed hair, detailed body, (detailed face and eyes:1.1), (depth of field:0.6), Leica, 8K HDR, High contrast, shadows, bokeh',
      negativePrompt:
        '(big breasts), (close-up photo) (deformed iris, deformed pupils, semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime, mutated hands and fingers:1.4), (deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, disconnected limbs, mutation, mutated, ugly, disgusting, amputation',
      engine: realisticEngine5,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/069c9197-1971-43bd-a0b2-7b2cce1db352.jpeg',
    },
    {
      id: '2',
      name: 'Porn',
      prefixPrompt:
        '(full-body in frame:1.1), (porn:1.1), (pov:1.1), (sex), photo of',
      suffixPrompt:
        '(detailed skin texture), cinematic lighting, sharp focus, (very detailed), detailed face, detailed eyes, high quality, high resolution, 8K HDR, High contrast, shadows, bokeh',
      negativePrompt:
        '(watermark), ((deformed face)), ((disfigured)), ((bad art)), (morphed body:1.2), ((deformed)), (disfigured face and eyes:1.1),  (digital:1.3), (3d:1.3), (thin hair:1.2), (blurry eyes:1.3), missing hands, (big breasts:1.3), (airbrushed), low quality, low resolution, overexposed, (unnatural body)',
      engine: realisticPornEngine,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/1697020143878.jpeg',
    },

    {
      id: '1.1',
      name: 'Realistic',
      prefixPrompt: 'RAW photo',
      suffixPrompt:
        '8k uhd, dslr, soft lighting, high quality, film grain, Fujifilm XT3, hasselblad, gigapixel, detailed hair, detailed body, (detailed face and eyes:1.1), (depth of field:0.6), Leica, 8K HDR, High contrast, shadows, bokeh',
      negativePrompt:
        '(big breasts), (deformed iris, deformed pupils, semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime, mutated hands and fingers:1.4), (deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, disconnected limbs, mutation, mutated, ugly, disgusting, amputation',
      engine: realisticEngine5,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/069c9197-1971-43bd-a0b2-7b2cce1db352.jpeg',
    },
    {
      id: '3',
      name: 'Hentai',
      prefixPrompt: 'hentai drawing',
      suffixPrompt:
        '((masterpiece)), (highly detailed:1.3), 8k wallpaper, (trending on Art Station)',
      negativePrompt:
        '(airbrushed:1.3), (extra feet:1.1), (extra hands:1.1) ((deformed face)), ((disfigured)), ((bad art)), ((deformed)), (disfigured face and eyes:1.1), (extra limbs:1.2), (disproportionate anatomy:1.2), (incorrect body), (text:1.2), (watermarks:1.2), (large breasts:1.2)',
      engine: hentaiGoldEngine,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/1697039083504.jpeg',
    },
    {
      id: '4',
      name: 'Digital',
      prefixPrompt: '(hyper realistic)',
      suffixPrompt:
        '(full body:1.1), (highly detailed face), (detailed skin), (detailed eyes), absurd resolution, masterpiece, trending on art station, ultra-detailed, (lifelike), vivid, intricate details, ((photorealistic)), visceral experience, realism, sharp lighting, deep shadows, (full vibrant colors)',
      negativePrompt:
        'ng_deepnegative_v1_75t, (low quality:1.4), logo, watermark, text, lowres, ((bad anatomy)), (bad hands), (blurry eyes), missing finger, extra digits, fewer digits, blurry, (mutated hands and fingers), (poorly drawn face), (mutation), ((deformed face)), (ugly), ((bad proportions)), (double head), logo, cropped, worst quality, close up, jpeg, humpbacked, long body, long neck, (jpeg artifacts), (deformed penis), (disembodied penis), (mutated penis), (fused body parts:1.2), double image, split screens, (underage:1.1), (large breasts), (cross eyed)',
      engine: digitalBabesEngine,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/a44a5d37-f3ba-4029-b294-c55843080638.jpeg',
    },
    {
      id: '1',
      name: 'Realistic - old',
      prefixPrompt: 'Full-body portrait photograph',
      suffixPrompt:
        'hasselblad, gigapixel, detailed hair, detailed body, (detailed face and eyes), (depth of field:0.6), Leica, 8K HDR, High contrast, shadows, bokeh',
      negativePrompt:
        'big breasts, ((deformed face)), ((disfigured)), ((bad art)), ((deformed)), (disfigured face and eyes:1.1), (digital:1.2), (digital render:1.2) (photoshop:1.1)',
      engine: realisticEngine5,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/1697039595293.jpeg',
    },
    {
      id: '6',
      name: 'Old School Hentai',
      prefixPrompt:
        '(Miyazaki style illustration:1.2), (professional hentai drawing)',
      suffixPrompt: '((masterpiece)), (highly detailed:1.3), 8k wallpaper',
      negativePrompt:
        '((airbrushed)), (eye contact), (worst quality, low quality:1.4), bad finger, watermark, lowres, ((bad anatomy)), ((bad hands)), missing finger, blurry, ((mutated hands and fingers)), (poorly drawn face), ((mutation)), ((deformed face)), (ugly), ((bad proportions)), (double head), monster, logo, cropped, worst quality, close up, normal quality, jpeg, humpbacked, long body, long neck, ((jpeg artifacts)), (deformed penis), ((disembodied penis)), (mutated penis), ((fused body parts:1.2))',
      engine: hentai3Engine,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/1700816624836.jpeg',
    },
    {
      id: '7',
      name: 'Comic',
      prefixPrompt: '(comic illustration)',
      suffixPrompt: '((masterpiece)), (highly detailed:1.3)',
      negativePrompt:
        '((deformed face)), ((disfigured)), ((bad art)), ((deformed)), (disfigured face and eyes:1.1), (worst quality:1.3), (low quality:1.3), (((watermark))), bad anatomy,, (extra limbs:1.2)',
      engine: digitalBabesEngine,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/1697039583447.jpeg',
    },
    {
      id: '8',
      name: 'Polaroid photograph',
      prefixPrompt: 'candid amateur Polaroid photo, high-res, very detailed,',
      suffixPrompt: '((masterpiece)), (highly detailed:1.3), 8k wallpaper',
      negativePrompt:
        '((deformed face)),((disfigured)), ((bad art)), ((deformed)), ((extra limbs)), ((close up)), blurry, (((duplicate))), ((morbid)), ((mutilated)), (out of frame), extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), (((mutation))), (((deformed))), blurry, ((bad anatomy)), (disfigured face and eyes:1.1), cgi, render, 3d image, computer generated, artificial, missing limbs, extra limbs, perfect skin, misplaced nipples, airbrushed skin, malformed belly-button, contorted limbs, twisted limbs',
      engine: realisticEngine4,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/1697040976289.jpeg',
    },
    {
      id: '0',
      name: 'No style',
      prefixPrompt: '',
      suffixPrompt: '',
      negativePrompt: '',
      engine: undefined,
      creatorUserId: '1',
      type: 'default',
    },
  ];
};

function rearrangeNoneStyleFirst(styles: StyleInterface[]): StyleInterface[] {
  const index = styles.findIndex((style) => style.id === '0');
  if (index > -1) {
    const [item] = styles.splice(index, 1);
    styles.unshift(item);
  }
  return styles;
}

export const getDefaultStylesForAllModal = (): StyleInterface[] =>
  rearrangeNoneStyleFirst(getDefaultStyles());

export const getPreviewStyles = (): StyleInterface[] => {
  // Step 1: Retrieve styles
  const recentlyUsedStyles = getRecentlyUsedStylesFromLocalStorage();
  const defStyles = getDefaultStyles().slice(0, 3);

  // Step 2: Create a Set of unique identifiers from recently used styles
  const recentlyUsedIds = new Set(recentlyUsedStyles.map((style) => style.id));

  // Step 3: Filter out default styles that are already in recently used styles
  const uniqueDefStyles = defStyles.filter(
    (style) => !recentlyUsedIds.has(style.id),
  );

  // Step 4: Combine and limit the styles
  const stylesToReturn = [...recentlyUsedStyles, ...uniqueDefStyles];
  return stylesToReturn.slice(0, 3);
};

export const getDefaultStyleViaId = (styleId: string): StyleInterface => {
  const noneId = '0';
  const noneStyle = getDefaultStyles().find(
    (style) => style.id === noneId,
  ) as unknown as StyleInterface;
  return getDefaultStyles().find((style) => style.id === styleId) ?? noneStyle;
};

export const getBgImageToShow = (style: StyleInterface) => {
  if (!style?.engine) {
    return 'placeholder.png';
  }

  const bgImageToShow = style.previewImageUrl
    ? style.previewImageUrl
    : getExampleImageSrc(style.engine);
  return `url(${bgImageToShow})`;
};

// useGetRecentlyUsedStyles, check recently used engines stuff copy paste here
