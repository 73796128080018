import styled from 'styled-components';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import useStartChat from '../../hooks/chat/useStartChat';
import StartChatButton from '../../components/common/StartChatButton';
import { FaEllipsisV } from 'react-icons/fa';
import { PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from '../../constants';
import { useState } from 'react';
import ShowMoreMenuModalInHomeGenerator from './ShowMoreMenuModalInHomeGenerator';

const ButtonsBelowGeneratedImagesInHome = () => {
  const { activeImage } = useGeneralContext();
  const { startChatWithFakeImage } = useStartChat();
  const [showMoreMenu, setShowMoreMenu] = useState(false);

  const handleStartChat = () => {
    startChatWithFakeImage(activeImage);
  };

  const handleOptionsClick = () => {
    setShowMoreMenu(true);
  };

  return (
    <Container>
      <StartChatButton onClick={handleStartChat} />
      <OptionsButton onClick={handleOptionsClick}>
        <FaEllipsisV />
      </OptionsButton>

      <ShowMoreMenuModalInHomeGenerator
        show={showMoreMenu}
        setShow={setShowMoreMenu}
      />
    </Container>
  );
};

export default ButtonsBelowGeneratedImagesInHome;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  padding: 0 16px;
  width: 100%;
`;

const OptionsButton = styled.button`
  position: absolute;
  right: 16px;
  top: 0px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  font-size: 1.2rem;
  color: ${SECONDARY_TEXT_COLOR};

  &:hover {
    color: ${PRIMARY_TEXT_COLOR};
  }
`;
