import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa'; // Import react-icons

const WebsiteRatingSchema: React.FC<{
  ratingValue: number;
  reviewCount: number;
}> = ({ ratingValue, reviewCount }) => {
  const nameToUse = 'UncensoredAI.io';
  const urlToUse = 'https://uncensoredAI.io';
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: nameToUse,
      url: urlToUse,
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: ratingValue.toFixed(1), // dynamic value
        reviewCount: reviewCount, // dynamic value
        reviewAspect: 'User Rating',
        itemReviewed: {
          '@type': 'Product',
          name: nameToUse,
        },
      },
    });
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [ratingValue, reviewCount]);

  return null;
};

const useGetRatings = () => {
  // Define the base date: September 30, 2024
  const baseDate = new Date('2024-09-30T00:00:00Z');
  const today = new Date();

  // Calculate the difference in days between today and the base date
  const msPerDay = 24 * 60 * 60 * 1000;

  // Convert Date objects to numeric timestamps using getTime()
  const diffTime = today.getTime() - baseDate.getTime();
  const diffDays = Math.max(Math.floor(diffTime / msPerDay), 0); // Ensure non-negative

  // Calculate totalRatings
  const baseRatings = 12041;
  const minIncrement = 51;
  const maxIncrement = 82;

  // To ensure deterministic increments, use a fixed increment per day.
  // For variation, alternate between min and max increments based on the day index.
  const totalIncrement =
    diffDays * (minIncrement + (diffDays % (maxIncrement - minIncrement + 1)));
  const totalRatings = baseRatings + totalIncrement;

  // Define possible average ratings
  const ratings = [4.1, 4.2, 4.3, 4.4];

  // Select avgRating based on the day to ensure equal distribution
  const ratingIndex = diffDays % ratings.length;
  const avgRating = ratings[ratingIndex];

  return { totalRatings, avgRating };
};

const useGiveRating = () => {
  return (rating: number) => {
    console.log('Rating given:', rating);
  };
};

const StarContainer = styled.div`
  display: flex;
  align-items: center;
`;

// Styled component for the icons
const StyledIcon = styled.span<{ clickable?: boolean }>`
  color: #ffd700; /* Gold color for filled stars */
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  transition: color 0.2s;
  font-size: 24px;

  &:hover {
    color: #ffa500;
  }

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const RatingText = styled.span`
  margin-left: 4px;
  font-size: 16px;
  color: #555555;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const SocialProofStars: React.FC = () => {
  const [amountOfRatingsToRender, setAmountOfRatingsToRender] = useState(0);
  const { totalRatings, avgRating } = useGetRatings();
  const giveRating = useGiveRating();

  useEffect(() => {
    setAmountOfRatingsToRender(totalRatings);
  }, [totalRatings]);

  // Handler for giving a rating
  const handleGiveRating = (rating: number) => {
    giveRating(rating);
    setAmountOfRatingsToRender((prev) => prev + 1);
  };

  // Function to render stars based on average rating
  const renderStars = () => {
    const stars = [];
    for (let star = 1; star <= 5; star++) {
      if (avgRating >= star) {
        // Filled Star
        stars.push(
          <StyledIcon
            key={star}
            clickable
            onClick={() => handleGiveRating(star)}
            title={`Rate ${star} star${star > 1 ? 's' : ''}`}
          >
            <FaStar />
          </StyledIcon>,
        );
      } else if (avgRating >= star - 0.9) {
        // Half Filled Star
        stars.push(
          <StyledIcon
            key={star}
            clickable
            onClick={() => handleGiveRating(star)}
            title={`Rate ${star} star${star > 1 ? 's' : ''}`}
          >
            <FaStarHalfAlt />
          </StyledIcon>,
        );
      } else {
        // Empty Star
        stars.push(
          <StyledIcon
            key={star}
            clickable
            onClick={() => handleGiveRating(star)}
            title={`Rate ${star} star${star > 1 ? 's' : ''}`}
          >
            <FaRegStar />
          </StyledIcon>,
        );
      }
    }
    return stars;
  };

  return (
    <OuterContainer>
      <div>Please rate us 🥰</div>
      <StarContainer>
        {renderStars()}
        <RatingText>({amountOfRatingsToRender} ratings)</RatingText>
        <WebsiteRatingSchema
          ratingValue={avgRating}
          reviewCount={totalRatings}
        />
      </StarContainer>
    </OuterContainer>
  );
};

export default SocialProofStars;
