import styled from 'styled-components';
import GeneratedImagesContainer from '../AdvancedModeTab/GeneratedImagesContainer';
import SaveButtonInGenerate from '../AdvancedModeTab/ButtonsBelowImage/SaveButtonInGenerate';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { sharedIconStyle } from '../../components/common/IconButtonStuff';
import { desktopMediaQuery } from '../../styleHelpers';
import { BORDER_COLOR } from '../../constants';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { toast } from 'react-toastify';
import StartChatButton from '../../components/common/StartChatButton';
import useStartChat from '../../hooks/chat/useStartChat';

const GeneratedImagesMagicMode = () => {
  return (
    <Container>
      <GeneratedImagesContainer hideRandomTip />
      <ButtonsBelowMagicModeImage />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 16px;

  ${desktopMediaQuery} {
    margin-left: 17vw;
  }
`;

const ButtonsBelowMagicModeImage = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const { setImageToBePosted } = useImagesContext();
  const { setShowCreateNewPostModal } = useGeneralContext();
  const { activeImage } = useGeneralContext();
  if (!activeImage.selectedTags) return null;

  const handleClickPost = () => {
    if (!loggedInUser) {
      toast.error('You need to be logged in to share images');
      return;
    }
    if (activeImage?.sharedImageRefId || activeImage.remixing) {
      toast.error('You can only post your original images');
      return;
    }
    activeImage && setImageToBePosted(activeImage);
    setShowCreateNewPostModal(true);
  };

  const { startChatWithFakeImage } = useStartChat();
  const handleStartChat = () => {
    startChatWithFakeImage(activeImage);
  };

  return (
    <OuterContainerForButtons>
      <ContainerForButtons>
        <PostContainer onClick={handleClickPost}>
          <PostIcon />
          Post
        </PostContainer>
        <StartChatButton onClick={handleStartChat} />
        <SaveButtonInGenerate image={activeImage} showSaveText={false} big />
      </ContainerForButtons>

      <HintText>
        You can hit "Create" to generate more images with the same options, or
        edit them below 👇
      </HintText>
    </OuterContainerForButtons>
  );
};

// TODO: fix this layout issue stuff
const OuterContainerForButtons = styled.div`
  display: flex;
  flex-direction: column;

  ${desktopMediaQuery} {
    margin-left: 6vw;
  }
`;

const ContainerForButtons = styled.div`
  display: flex;
  justify-content: space-evenly;

  margin-top: 32px;
  margin-left: 8px;

  ${desktopMediaQuery} {
    margin-left: -15vw;
  }
`;

const PostContainer = styled.div`
  ${sharedIconStyle}
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 20px;
  ${desktopMediaQuery} {
    font-size: 30px;
  }
`;

const PostIcon = styled(AiOutlinePlusSquare)``;

const HintText = styled.div`
  border-top: 0.5px dotted ${BORDER_COLOR};
  font-size: 16px;
  margin-top: 40px;
  padding: 40px;
  padding-top: 48px;
  padding-bottom: 8px;

  ${desktopMediaQuery} {
    margin-left: -15vw;
  }
`;

export default GeneratedImagesMagicMode;
